import mock from "../../mock";

const data = {
  contents: [
    {
      Loans: [
        {
          LoanNumber: "",
        },
      ],
      FileNumber: "TEST-121545",
      FileID: 21660,
      Status: {
        StatusID: 2,
        Name: "Open",
      },
      Buyers: [
        {
          LegalEntityID: 45150,
          EntityType: "INDIVIDUAL",
          IsPrimaryTransactee: true,
          Primary: {
            First: "Demo",
            Last: "One",
          },
          Secondary: {},
          Address: {},
        },
      ],
      Partners: [
        {
          PrimaryEmployee: {
            FirstName: "PC",
            LastName: "Company",
          },
          RemoteFileNumber: "",
          PartnerTypeID: 1,
          PartnerType: {
            PartnerTypeID: 1,
            PartnerTypeName: "Client",
          },
          PartnerName: "QACompany",
        },
      ],
      TransactionProductType: {
        TransactionType: "Refinance",
        TransactionTypeID: 4,
        ProductType: "Refinance",
        ProductTypeID: 4,
      },
      Properties: [
        {
          IsPrimary: true,
          StreetNumber: "123",
          StreetName: "Maple Street",
          City: "San Diego",
          State: "CA",
          County: "San Diego",
          Zip: "92101",
          Subdivision: "",
          ParcelID: "",
          PropertyID: 21661,
        },
      ],
      Dates: {
        OpenedDate: "/Date(1597323572460-0000)/",
      },
    },
    {
      Loans: [
        {
          LoanNumber: "",
        },
      ],
      FileNumber: "TEST-121546",
      FileID: 21661,
      Status: {
        StatusID: 2,
        Name: "Open",
      },
      Buyers: [
        {
          LegalEntityID: 45151,
          EntityType: "INDIVIDUAL",
          IsPrimaryTransactee: true,
          Primary: {
            First: "Demo",
            Last: "Three",
          },
          Secondary: {},
          Address: {},
        },
      ],
      Partners: [
        {
          PrimaryEmployee: {
            FirstName: "PC",
            LastName: "Company",
          },
          RemoteFileNumber: "",
          PartnerTypeID: 1,
          PartnerType: {
            PartnerTypeID: 1,
            PartnerTypeName: "Client",
          },
          PartnerName: "QACompany",
        },
      ],
      TransactionProductType: {
        TransactionType: "Refinance",
        TransactionTypeID: 4,
        ProductType: "Refinance",
        ProductTypeID: 4,
      },
      Properties: [
        {
          IsPrimary: true,
          StreetNumber: "11349",
          StreetName: "Aristotle Avenue #6-301",
          City: "Fairfax",
          State: "VA",
          County: "Fairfax City",
          Zip: "22030",
          Subdivision: "",
          ParcelID: "",
          PropertyID: 21662,
        },
      ],
      Dates: {
        OpenedDate: "/Date(1597323686087-0000)/",
      },
    },
    {
      Loans: [
        {
          LoanNumber: "",
        },
      ],
      FileNumber: "TEST-121547",
      FileID: 21662,
      Status: {
        StatusID: 2,
        Name: "Open",
      },
      Buyers: [
        {
          LegalEntityID: 45152,
          EntityType: "INDIVIDUAL",
          IsPrimaryTransactee: true,
          Primary: {
            First: "Demo",
            Last: "Two",
          },
          Secondary: {},
          Address: {},
        },
      ],
      Partners: [
        {
          PrimaryEmployee: {
            FirstName: "PC",
            LastName: "Company",
          },
          RemoteFileNumber: "",
          PartnerTypeID: 1,
          PartnerType: {
            PartnerTypeID: 1,
            PartnerTypeName: "Client",
          },
          PartnerName: "QACompany",
        },
      ],
      TransactionProductType: {
        TransactionType: "Refinance",
        TransactionTypeID: 4,
        ProductType: "Refinance",
        ProductTypeID: 4,
      },
      Properties: [
        {
          IsPrimary: true,
          StreetNumber: "8452",
          StreetName: "Millwood Drive",
          City: "Springfield",
          State: "VA",
          County: "Fairfax",
          Zip: "22152",
          Subdivision: "",
          ParcelID: "",
          PropertyID: 21663,
        },
      ],
      Dates: {
        OpenedDate: "/Date(1597323748023-0000)/",
      },
    },
  ],
};

// GET ALL DATA
mock.onPost("/resware/search1").reply((request) => {
  const { FileNumber } = JSON.parse(request.data);
  const contents = data.contents.filter((item) =>
    item.FileNumber.toLowerCase().includes(FileNumber.toLowerCase())
  );
  if (contents.length < 1) {
    return [
      404,
      {
        error: "",
      },
    ];
  } else if (contents.length > 1) {
    return [
      400,
      {
        error:
          "Search returned multiple files. Try again with a more specific File Number.",
      },
    ];
  }
  return [200, contents[0]];
});
