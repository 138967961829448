const initialState = {
  analyze: null,
  params: null,
};

const selectDocumentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ANALYZE":
      return { ...state, analyze: action.data, params: action.params };
    case "SET_FILE_LENDER":
      return {
        ...state,
        analyze: { id: action.submissionId },
        params: {
          ...state.params,
          fileLender: { data: action.fileLender },
        },
      };
    case "SET_FILE_TITLE":
      return {
        ...state,
        analyze: { id: action.submissionId },
        params: {
          ...state.params,
          fileEscrow: { data: action.fileEscrow },
        },
      };
    default:
      return state;
  }
};

export default selectDocumentsReducer;
