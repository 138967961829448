import mock from "../../mock";

const data = {
  contents: {
    "1.7.0": "Appraised Prop. Value",
    1.1: "6/11/2021",
    1.2: "6/17/2021",
    1.3: "6/22/2021",
    1.4: "Known  TITLE COMPANY",
    1.5: "SBTREF21338519",
    1.6: {
      subpremise: "",
      street_number: "2209",
      route: "Chadbourne Ave",
      neighborhood: "Regent",
      locality: "Madison",
      administrative_area_level_2: "Dane",
      administrative_area_level_1: "WI",
      country: "US",
      postal_code: "53726",
      postal_code_suffix: "3929",
      raw_address: "2209 CHADBOURNE AVE  Madison ,  WI    53726",
      geocode_result: [
        {
          address_components: [
            { long_name: "2209", short_name: "2209", types: ["street_number"] },
            {
              long_name: "Chadbourne Avenue",
              short_name: "Chadbourne Ave",
              types: ["route"],
            },
            {
              long_name: "Regent",
              short_name: "Regent",
              types: ["neighborhood", "political"],
            },
            {
              long_name: "Madison",
              short_name: "Madison",
              types: ["locality", "political"],
            },
            {
              long_name: "Dane County",
              short_name: "Dane County",
              types: ["administrative_area_level_2", "political"],
            },
            {
              long_name: "Wisconsin",
              short_name: "WI",
              types: ["administrative_area_level_1", "political"],
            },
            {
              long_name: "United States",
              short_name: "US",
              types: ["country", "political"],
            },
            { long_name: "53726", short_name: "53726", types: ["postal_code"] },
            {
              long_name: "3929",
              short_name: "3929",
              types: ["postal_code_suffix"],
            },
          ],
          formatted_address: "2209 Chadbourne Ave, Madison, WI 53726, USA",
          geometry: {
            bounds: {
              northeast: { lat: 43.0685908, lng: -89.4242666 },
              southwest: { lat: 43.0684541, lng: -89.4243812 },
            },
            location: { lat: 43.0685212, lng: -89.4243225 },
            location_type: "ROOFTOP",
            viewport: {
              northeast: { lat: 43.0698714302915, lng: -89.4229749197085 },
              southwest: { lat: 43.0671734697085, lng: -89.4256728802915 },
            },
          },
          place_id: "ChIJNVFpBu-sB4gR1e8Ps_98wig",
          types: ["premise"],
        },
      ],
    },
    1.7: "695000",
    2.1: {
      subpremise: "",
      street_number: "2209",
      route: "Chadbourne Ave",
      neighborhood: "Regent",
      locality: "Madison",
      administrative_area_level_2: "Dane",
      administrative_area_level_1: "WI",
      country: "US",
      postal_code: "53726",
      postal_code_suffix: "3929",
      raw_address: "Matthew C. Younkle  2209 Chadbourne Ave  Madison WI 53726",
      geocode_result: [
        {
          address_components: [
            { long_name: "2209", short_name: "2209", types: ["street_number"] },
            {
              long_name: "Chadbourne Avenue",
              short_name: "Chadbourne Ave",
              types: ["route"],
            },
            {
              long_name: "Regent",
              short_name: "Regent",
              types: ["neighborhood", "political"],
            },
            {
              long_name: "Madison",
              short_name: "Madison",
              types: ["locality", "political"],
            },
            {
              long_name: "Dane County",
              short_name: "Dane County",
              types: ["administrative_area_level_2", "political"],
            },
            {
              long_name: "Wisconsin",
              short_name: "WI",
              types: ["administrative_area_level_1", "political"],
            },
            {
              long_name: "United States",
              short_name: "US",
              types: ["country", "political"],
            },
            { long_name: "53726", short_name: "53726", types: ["postal_code"] },
            {
              long_name: "3929",
              short_name: "3929",
              types: ["postal_code_suffix"],
            },
          ],
          formatted_address: "2209 Chadbourne Ave, Madison, WI 53726, USA",
          geometry: {
            bounds: {
              northeast: { lat: 43.0685908, lng: -89.4242666 },
              southwest: { lat: 43.0684541, lng: -89.4243812 },
            },
            location: { lat: 43.0685212, lng: -89.4243225 },
            location_type: "ROOFTOP",
            viewport: {
              northeast: { lat: 43.0698714302915, lng: -89.4229749197085 },
              southwest: { lat: 43.0671734697085, lng: -89.4256728802915 },
            },
          },
          partial_match: true,
          place_id: "ChIJNVFpBu-sB4gR1e8Ps_98wig",
          types: ["premise"],
        },
      ],
      legal_entity: "Matthew C. Younkle",
    },
    2.2: "",
    2.3: "Bank",
    "3.1.0": "Year",
    3.1: "30",
    "3.1.month": "360",
    3.2: "Refinance",
    3.3: "Fixed Rate",
    3.5: "0160416178",
    3.6: "",
    "3.4.1": true,
    4.3: "Monthly",
    "4.1.1": "268300",
    "4.1.2": "NO",
    "4.2.1": "3",
    "4.2.2": "NO",
    "4.3.1": "1131.16",
    "4.3.2": "NO",
    "4.4.2": "NO",
    "4.5.2": "NO",
    "6.1.1": "5337.91",
    "6.1.2": "2615.55",
    "6.1.3": "2722.36",
    "6.1.4": "0",
    "6.2.1": "1551.84",
    "6.2.2": "To",
    5.5: "Total Monthly",
    "5.1.1": "Years 1-30",
    "5.2.1": "1131.16",
    "5.2.2": "1131.16",
    "5.3.1": "0",
    "5.4.1": "595.28",
    "5.5.1": "1726.44",
    "5.6.2": "Monthly",
    "5.6.3.1": "YES",
    "5.6.1": "651.28",
    "5.6.4.1": "YES",
    "5.6.5.0": "Other: HOA Dues",
    "5.6.5.1": "NO",
    "5.6.3": true,
    "5.6.4": true,
    "5.6.5": true,
    "5.1.1.1": "1",
    "5.1.1.2": "30",
    "5.0.max": "30",
    "7.1.1": "579.15",
    "7.2.1": "0.05",
    "7.2.0": "",
    7.2: { "7.2.2": "134.15" },
    7.3: [
      { "7.3.2": "445.00", 7.3: "Underwriting Fees", map: "UnderwritingFee" },
    ],
    "7.4.1": "2036.40",
    7.5: [
      {
        "7.5.2": "835.00",
        7.5: "Appraisal Fee",
        "7.5.1": "to BROAD STRET VALUATIONS",
        map: "AppraisalFee",
      },
      {
        "7.5.2": "29.00",
        7.5: "Credit Report",
        "7.5.1": "to Factual Data",
        map: "CreditReportFee",
      },
      {
        "7.5.2": "13.00",
        7.5: "Flood Certification",
        "7.5.1": "to CoreLogic Flood Services",
        map: "FloodCertification",
      },
      {
        "7.5.2": "500.00",
        7.5: "Title - Closing Fee to Known TITLE",
        map: "Other",
      },
      {
        "7.5.2": "600.00",
        7.5: "Title - Lender's Title Insurance to Known TITLE",
        map: "Other",
      },
      {
        "7.5.2": "59.40",
        7.5: "Title - Sales Tax (Owner's or Lender's Title to Known TITLE",
        map: "Other",
      },
    ],
    "7.8.1": "2615.55",
    "7.9.2": "2615.55",
    "8.1.1": "140.00",
    "8.2.1": { Deed: "", Mortgage: "140.00" },
    "8.2.2": "140.00",
    8.2: "Recording Fees",
    "8.2.1.mapped": { Mortgage: "Buyer" },
    "8.2.1.poc": { Mortgage: "false" },
    "8.4.1": "201.24",
    8.5: "Homeowner's Insurance Premium (   mo.)",
    8.6: "Mortgage Insurance Premium (   mo.)",
    "8.7.2": "201.24",
    8.7: "Prepaid Interest ( $22.36 per day from 6/22/21 to 7/1/21 )",
    8.8: "Property Taxes (   mo.)",
    "8.5.1": "",
    "8.6.1": "",
    "8.8.1": "",
    "8.7.1": "22.36",
    "8.10.1": "2381.12",
    "8.11.2": "307.00",
    8.11: "Homeowner's Insurance",
    "8.11.1": "76.75 per month for 4 mo.",
    8.12: "Mortgage Insurance",
    "8.12.1": "per month for    mo.",
    "8.13.2": "2074.12",
    8.13: "Property Taxes",
    "8.13.1": "518.53 per month for 4 mo.",
    "8.15.2": "0.00",
    8.15: "Aggregate Adjustment",
    "8.18.1": "2722.36",
    "8.19.2": "2722.36",
    "9.1.1": "5337.91",
    "9.2.2": "5337.91",
    10.3: "5337.91",
    "10.10.1": "268300.00",
    "10.1.1": "-7040.00",
    "10.2.1": "0",
    "10.11.1": "-261278.00",
    "10.9.1": "18.00",
    "10.10.2": "268300.00",
    "10.1.2": "-5337.91",
    "10.2.2": "0",
    "10.11.2": "-261410.25",
    "10.9.2": "1551.84",
    "10.10.3": "NO",
    "10.1.3": "YES",
    "10.2.3": "NO",
    "10.11.3": "YES",
    "10.1.4":
      " See Total Loan Costs (D) Total Loan Costs (D) and Total Other Costs (I) Total Other Costs (I).",
    "10.11.4": " See Payoffs and Payments (K) Payoffs and Payments (K).",
    "10.3.2": "5,337.91",
    "10.9.3": "From",
    "10.9.4": "To",
    "25.2.1": "261410.25",
    25: [{ key: "JPMCB - HOME LENDING", value: "261410.25", map: "Other" }],
    17.1: "No",
    17.2: "No",
    17.4: "No",
    17.5: "No",
    "17.3.1": "15",
    "17.3.3": "5",
    17.6: {
      street_number: "9",
      route: "CHADBOURNE AVE",
      subpremise: "UNIT 2 0 W 2",
      locality: "MADISON",
      administrative_area_level_1: "I",
      postal_code: "53726",
      individual: [{ first_name: "2", last_name: "2", middle_name: "0 , ," }],
    },
    18.1: "Yes",
    18.2: "6548.08",
    18.3: "616.00",
    18.5: "595.28",
    18.4: "2381.12",
    "18.2.1": "Property Taxes, Homeowner's Insurance",
    "18.3.1": "HOA Dues",
    21.1: "410036.61",
    21.2: "140272.61",
    21.3: "266947.21",
    21.4: "3.04",
    21.5: "51.853",
    22.1: "May",
    "23.1.0": "Name",
    "23.1.1": "Bank",
    "23.1.5": " TITLE COMPANY Known",
    "23.2.0": "Address",
    "23.2.1": {
      subpremise: "",
      street_number: "12655",
      route: "Olive Blvd",
      neighborhood: "",
      locality: "St. Louis",
      administrative_area_level_2: "St Louis",
      administrative_area_level_1: "MO",
      country: "US",
      postal_code: "63141",
      postal_code_suffix: "",
      raw_address: "12655 Olive Blvd Saint  .,  Louis, MO 63141",
      geocode_result: [
        {
          address_components: [
            {
              long_name: "12655",
              short_name: "12655",
              types: ["street_number"],
            },
            {
              long_name: "Olive Boulevard",
              short_name: "Olive Blvd",
              types: ["route"],
            },
            {
              long_name: "St. Louis",
              short_name: "St. Louis",
              types: ["locality", "political"],
            },
            {
              long_name: "Maryland Heights Township",
              short_name: "Maryland Heights Township",
              types: ["administrative_area_level_3", "political"],
            },
            {
              long_name: "St. Louis County",
              short_name: "St Louis County",
              types: ["administrative_area_level_2", "political"],
            },
            {
              long_name: "Missouri",
              short_name: "MO",
              types: ["administrative_area_level_1", "political"],
            },
            {
              long_name: "United States",
              short_name: "US",
              types: ["country", "political"],
            },
            { long_name: "63141", short_name: "63141", types: ["postal_code"] },
          ],
          formatted_address: "12655 Olive Blvd, St. Louis, MO 63141, USA",
          geometry: {
            location: { lat: 38.6781719, lng: -90.4677248 },
            location_type: "ROOFTOP",
            viewport: {
              northeast: { lat: 38.67952088029151, lng: -90.46637581970849 },
              southwest: { lat: 38.67682291970851, lng: -90.46907378029151 },
            },
          },
          place_id: "ChIJp6Y7hGst34cRoDLEIY4x6Ac",
          plus_code: {
            compound_code: "MGHJ+7W St. Louis, MO, USA",
            global_code: "86CFMGHJ+7W",
          },
          types: [
            "establishment",
            "health",
            "insurance_agency",
            "point_of_interest",
          ],
        },
      ],
    },
    "23.3.0": "NMLS ID",
    "23.3.1": "123456",
    "23.4.0": "WA",
    "23.4.5": "1234",
    "23.5.0": "Contact",
    "23.5.1": "Jon Doe",
    "23.5.5": "CLOSING DEPT",
    "23.6.0": "Contact NMLS ID",
    "23.6.1": "123456",
    "23.7.0": "Contact WA License ID",
    "23.8.0": "Email",
    "23.8.1": "@bank .com jdoe _bank",
    "23.8.5": "CLOSING@ .COM Known",
    "23.9.0": "Phone",
    "23.9.1": "- - 123 456 7890",
    "23.9.5": "800- - 6 123 45 7",
    "23.2.5.unparsed_legal": "  12655 Olive Blvd.  Saint L , MO 63 ouis 141",
    "8.11.1.0": "76.75",
    "8.11.1.1": "4",
    "8.12.1.0": "",
    "8.12.1.1": "",
    "8.13.1.0": "518.53",
    "8.13.1.1": "4",
    "5.6.5.0.map": "HomeownersAssociationDues",
    "3.2.map": "Refinance",
    "5.5.map": "Monthly",
    "5.6.2.map": "Monthly",
    "8.5.map": "HomeownersInsurancePremium",
    "8.6.map": "MortgageInsurancePremium",
    "8.7.map": "PrepaidInterest",
    "8.8.map": "CountyPropertyTax",
    CreatedDatetime: "2021-08-07T18:23:31-07:00",
  },
};

// GET ALL DATA
mock.onGet("/api/pdf/lender").reply(200, data.contents);
