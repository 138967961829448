import { get } from "lodash";
// ** Initial State
const initialState = {
  searchPdfResults: null,
  status: 0,
};

const searchPdfReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SEARCH_PDF":
      return {
        ...state,
        searchPdfResults: get(action, "value.data", []),
        status: get(action, "value.status", 0),
      };
    default:
      return state;
  }
};

export default searchPdfReducer;
