const initialState = {
  diff: null,
  resolution: null,
  resolved: null,
  viewOnly: false,
};

const pdfReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PDF_DIFF":
      return { ...state, diff: action.data };
    case "PDF_GET_RESOLUTION":
      return { ...state, resolution: action.data };
    case "PDF_RESOLVED":
      return { ...state, resolved: action.data };
    case "PDF_VIEW_ONLY":
      return { ...state, viewOnly: action.data };
    default:
      return state;
  }
};

export default pdfReducer;
