import mock from "../mock";

const data = {
  contents: [
    {
      id: "1.7.0",
      reference: "Sale Price",
      value: "$152,021.00",
    },
  ],
};

// GET ALL DATA
mock.onGet("/api/pdf/data-value-old").reply(200, data.contents);
