import mock from "../../mock";

const data = {
  contents: {
    "1.7.0": "Appraised Prop. Value",
    1.1: "6/11/2021",
    1.2: "6/17/2021",
    1.3: "6/22/2021",
    1.4: "Known Title Company",
    1.5: "SBTREF21338519",
    1.6: {
      subpremise: "",
      street_number: "2209",
      route: "Chadbourne Ave",
      neighborhood: "Regent",
      locality: "Madison",
      administrative_area_level_2: "Dane",
      administrative_area_level_1: "WI",
      country: "US",
      postal_code: "53726",
      postal_code_suffix: "3929",
      raw_address: "2209 Chadbourne Ave  Madison, WI 53726 (123456-0-050)",
      geocode_result: [
        {
          address_components: [
            { long_name: "2209", short_name: "2209", types: ["street_number"] },
            {
              long_name: "Chadbourne Avenue",
              short_name: "Chadbourne Ave",
              types: ["route"],
            },
            {
              long_name: "Regent",
              short_name: "Regent",
              types: ["neighborhood", "political"],
            },
            {
              long_name: "Madison",
              short_name: "Madison",
              types: ["locality", "political"],
            },
            {
              long_name: "Dane County",
              short_name: "Dane County",
              types: ["administrative_area_level_2", "political"],
            },
            {
              long_name: "Wisconsin",
              short_name: "WI",
              types: ["administrative_area_level_1", "political"],
            },
            {
              long_name: "United States",
              short_name: "US",
              types: ["country", "political"],
            },
            { long_name: "53726", short_name: "53726", types: ["postal_code"] },
            {
              long_name: "3929",
              short_name: "3929",
              types: ["postal_code_suffix"],
            },
          ],
          formatted_address: "2209 Chadbourne Ave, Madison, WI 53726, USA",
          geometry: {
            bounds: {
              northeast: { lat: 43.0685908, lng: -89.4242666 },
              southwest: { lat: 43.0684541, lng: -89.4243812 },
            },
            location: { lat: 43.0685212, lng: -89.4243225 },
            location_type: "ROOFTOP",
            viewport: {
              northeast: { lat: 43.0698714302915, lng: -89.4229749197085 },
              southwest: { lat: 43.0671734697085, lng: -89.4256728802915 },
            },
          },
          partial_match: true,
          place_id: "ChIJNVFpBu-sB4gR1e8Ps_98wig",
          types: ["premise"],
        },
      ],
    },
    1.7: "",
    2.1: {
      subpremise: "",
      street_number: "2209",
      route: "Chadbourne Ave",
      neighborhood: "Regent",
      locality: "Madison",
      administrative_area_level_2: "Dane",
      administrative_area_level_1: "WI",
      country: "US",
      postal_code: "53726",
      postal_code_suffix: "3929",
      raw_address: "Matthew C. Younkle  2209 Chadbourne Ave  Madison, WI 53726",
      geocode_result: [
        {
          address_components: [
            { long_name: "2209", short_name: "2209", types: ["street_number"] },
            {
              long_name: "Chadbourne Avenue",
              short_name: "Chadbourne Ave",
              types: ["route"],
            },
            {
              long_name: "Regent",
              short_name: "Regent",
              types: ["neighborhood", "political"],
            },
            {
              long_name: "Madison",
              short_name: "Madison",
              types: ["locality", "political"],
            },
            {
              long_name: "Dane County",
              short_name: "Dane County",
              types: ["administrative_area_level_2", "political"],
            },
            {
              long_name: "Wisconsin",
              short_name: "WI",
              types: ["administrative_area_level_1", "political"],
            },
            {
              long_name: "United States",
              short_name: "US",
              types: ["country", "political"],
            },
            { long_name: "53726", short_name: "53726", types: ["postal_code"] },
            {
              long_name: "3929",
              short_name: "3929",
              types: ["postal_code_suffix"],
            },
          ],
          formatted_address: "2209 Chadbourne Ave, Madison, WI 53726, USA",
          geometry: {
            bounds: {
              northeast: { lat: 43.0685908, lng: -89.4242666 },
              southwest: { lat: 43.0684541, lng: -89.4243812 },
            },
            location: { lat: 43.0685212, lng: -89.4243225 },
            location_type: "ROOFTOP",
            viewport: {
              northeast: { lat: 43.0698714302915, lng: -89.4229749197085 },
              southwest: { lat: 43.0671734697085, lng: -89.4256728802915 },
            },
          },
          partial_match: true,
          place_id: "ChIJNVFpBu-sB4gR1e8Ps_98wig",
          types: ["premise"],
        },
      ],
      legal_entity: "Matthew C. Younkle",
    },
    2.2: "",
    2.3: "Bank",
    "3.1.0": "Year",
    3.1: "",
    3.2: "Loan Only/Refi - Standard",
    3.3: "",
    3.5: "0160416178",
    3.6: "",
    "3.4.4": true,
    4.3: "Monthly",
    "4.1.1": "268300",
    "4.1.2": "NO",
    "4.2.2": "NO",
    "4.3.2": "NO",
    "4.4.2": "NO",
    "4.5.2": "NO",
    "6.1.1": "1159.40",
    "6.1.2": "1159.40",
    "6.1.3": "0.00",
    "6.1.4": "0.00",
    "6.2.1": "5730.35",
    "6.2.2": "To",
    5.5: "Total Monthly",
    "5.6.1": "0.00",
    "5.6.2": "a month",
    "5.6.5.0": "Other:",
    "7.1.1": "0.00",
    "7.2.1": "",
    "7.2.0": "",
    "7.4.1": "0.00",
    "7.6.1": "1159.40",
    7.7: [
      {
        "7.7.2": "600.00",
        7.7: "Title - Lender's Title Insurance to Known Title Company",
        map: "TitleInsuranceFee",
      },
      {
        "7.7.2": "500.00",
        7.7: "Title - Settlement or closing fee to Known Title Company",
        map: "SettlementFee",
      },
      {
        "7.7.2": "59.40",
        7.7: "Title - Title Charges Sales Tax to Known Title Company",
        map: "Other",
      },
    ],
    "7.8.1": "1159.40",
    "7.9.2": "1159.40",
    "7.9.3": "0.00",
    "8.1.1": "0.00",
    "8.2.1": { Deed: "", Mortgage: "", Release: "" },
    "8.4.1": "0.00",
    8.5: "Homeowner's Insurance Premium ( 12 mo.) to",
    8.6: "Mortgage Insurance Premium ( 12 mo.)",
    8.7: "Prepaid Interest (  per day from 6/22/2021 to 7/1/2021 )",
    "8.5.1": "12",
    "8.6.1": "12",
    "8.7.1": "",
    "8.10.1": "0.00",
    8.11: "Homeowner's Insurance",
    "8.11.1": "per month for 12 mo.",
    8.12: "Mortgage Insurance",
    "8.12.1": "per month for 12 mo.",
    8.13: "Property Taxes",
    "8.13.1": "per month for 12 mo.",
    8.15: "Aggregate Adjustment",
    "8.16.1": "0.00",
    "8.18.1": "0.00",
    "8.19.2": "0.00",
    "8.19.3": "0.00",
    "9.1.1": "1159.40",
    "9.2.3": "0.00",
    "9.2.2": "1159.40",
    "10.10.1": "0",
    "10.10.2": "268300.00",
    "10.10.3": "NO",
    "10.1.1": "0",
    "10.1.2": "-1159.40",
    "10.1.3": "NO",
    "10.2.1": "0",
    "10.2.2": "0",
    "10.2.3": "NO",
    "10.11.1": "0",
    "10.11.2": "-261410.25",
    "10.11.3": "NO",
    "10.9.1": "0",
    "10.9.2": "5730.35",
    "10.9.4": "To",
    "25.2.1": "261410.25",
    25: [
      {
        key: "First Position Mortgage Lien to JP Morgan Chase Bank, NA",
        value: "261410.25",
        map: "FirstPositionMortgageLien",
      },
    ],
    17.1: "No",
    17.2: "No",
    17.4: "No",
    17.5: "Accept",
    17.6: {
      subpremise: "",
      street_number: "2209",
      route: "Chadbourne Ave",
      neighborhood: "Regent",
      locality: "Madison",
      administrative_area_level_2: "Dane",
      administrative_area_level_1: "WI",
      country: "US",
      postal_code: "53726",
      postal_code_suffix: "3929",
      raw_address: "2209 Chadbourne Ave, Madison, WI 53726",
      geocode_result: [
        {
          address_components: [
            { long_name: "2209", short_name: "2209", types: ["street_number"] },
            {
              long_name: "Chadbourne Avenue",
              short_name: "Chadbourne Ave",
              types: ["route"],
            },
            {
              long_name: "Regent",
              short_name: "Regent",
              types: ["neighborhood", "political"],
            },
            {
              long_name: "Madison",
              short_name: "Madison",
              types: ["locality", "political"],
            },
            {
              long_name: "Dane County",
              short_name: "Dane County",
              types: ["administrative_area_level_2", "political"],
            },
            {
              long_name: "Wisconsin",
              short_name: "WI",
              types: ["administrative_area_level_1", "political"],
            },
            {
              long_name: "United States",
              short_name: "US",
              types: ["country", "political"],
            },
            { long_name: "53726", short_name: "53726", types: ["postal_code"] },
            {
              long_name: "3929",
              short_name: "3929",
              types: ["postal_code_suffix"],
            },
          ],
          formatted_address: "2209 Chadbourne Ave, Madison, WI 53726, USA",
          geometry: {
            bounds: {
              northeast: { lat: 43.0685908, lng: -89.4242666 },
              southwest: { lat: 43.0684541, lng: -89.4243812 },
            },
            location: { lat: 43.0685212, lng: -89.4243225 },
            location_type: "ROOFTOP",
            viewport: {
              northeast: { lat: 43.0698714302915, lng: -89.4229749197085 },
              southwest: { lat: 43.0671734697085, lng: -89.4256728802915 },
            },
          },
          place_id: "ChIJNVFpBu-sB4gR1e8Ps_98wig",
          types: ["premise"],
        },
      ],
    },
    18.4: "0.00",
    18.5: "0.00",
    "18.3.1": "Property Taxes; Homeowner's Insurance",
    "18.1.1": "WillNot",
    21.1: "0.00",
    21.2: "0.00",
    21.3: "0.00",
    21.4: "0",
    21.5: "0",
    22.1: "May",
    "23.1.0": "Name",
    "23.1.1": "Bank",
    "23.1.5": "Known Title Company",
    "23.2.0": "Address",
    "23.2.1": {
      subpremise: "",
      street_number: "12655",
      route: "Olive Blvd",
      neighborhood: "",
      locality: "St. Louis",
      administrative_area_level_2: "St Louis",
      administrative_area_level_1: "MO",
      country: "US",
      postal_code: "63141",
      postal_code_suffix: "",
      raw_address: "12655 Olive Blvd., Saint  Louis, MO 63141",
      geocode_result: [
        {
          address_components: [
            {
              long_name: "12655",
              short_name: "12655",
              types: ["street_number"],
            },
            {
              long_name: "Olive Boulevard",
              short_name: "Olive Blvd",
              types: ["route"],
            },
            {
              long_name: "St. Louis",
              short_name: "St. Louis",
              types: ["locality", "political"],
            },
            {
              long_name: "Maryland Heights Township",
              short_name: "Maryland Heights Township",
              types: ["administrative_area_level_3", "political"],
            },
            {
              long_name: "St. Louis County",
              short_name: "St Louis County",
              types: ["administrative_area_level_2", "political"],
            },
            {
              long_name: "Missouri",
              short_name: "MO",
              types: ["administrative_area_level_1", "political"],
            },
            {
              long_name: "United States",
              short_name: "US",
              types: ["country", "political"],
            },
            { long_name: "63141", short_name: "63141", types: ["postal_code"] },
          ],
          formatted_address: "12655 Olive Blvd, St. Louis, MO 63141, USA",
          geometry: {
            location: { lat: 38.6781719, lng: -90.4677248 },
            location_type: "ROOFTOP",
            viewport: {
              northeast: { lat: 38.67952088029151, lng: -90.46637581970849 },
              southwest: { lat: 38.67682291970851, lng: -90.46907378029151 },
            },
          },
          place_id: "ChIJp6Y7hGst34cRoDLEIY4x6Ac",
          plus_code: {
            compound_code: "MGHJ+7W St. Louis, MO, USA",
            global_code: "86CFMGHJ+7W",
          },
          types: [
            "establishment",
            "health",
            "insurance_agency",
            "point_of_interest",
          ],
        },
      ],
    },
    "23.2.5": {
      subpremise: "",
      street_number: "12655",
      route: "Olive Blvd",
      neighborhood: "",
      locality: "St. Louis",
      administrative_area_level_2: "St Louis",
      administrative_area_level_1: "MO",
      country: "US",
      postal_code: "63141",
      postal_code_suffix: "",
      raw_address: "12655 Olive Blvd.  Saint Louis, MO 63141",
      geocode_result: [
        {
          address_components: [
            {
              long_name: "12655",
              short_name: "12655",
              types: ["street_number"],
            },
            {
              long_name: "Olive Boulevard",
              short_name: "Olive Blvd",
              types: ["route"],
            },
            {
              long_name: "St. Louis",
              short_name: "St. Louis",
              types: ["locality", "political"],
            },
            {
              long_name: "Maryland Heights Township",
              short_name: "Maryland Heights Township",
              types: ["administrative_area_level_3", "political"],
            },
            {
              long_name: "St. Louis County",
              short_name: "St Louis County",
              types: ["administrative_area_level_2", "political"],
            },
            {
              long_name: "Missouri",
              short_name: "MO",
              types: ["administrative_area_level_1", "political"],
            },
            {
              long_name: "United States",
              short_name: "US",
              types: ["country", "political"],
            },
            { long_name: "63141", short_name: "63141", types: ["postal_code"] },
          ],
          formatted_address: "12655 Olive Blvd, St. Louis, MO 63141, USA",
          geometry: {
            location: { lat: 38.6781719, lng: -90.4677248 },
            location_type: "ROOFTOP",
            viewport: {
              northeast: { lat: 38.67952088029151, lng: -90.46637581970849 },
              southwest: { lat: 38.67682291970851, lng: -90.46907378029151 },
            },
          },
          place_id: "ChIJp6Y7hGst34cRoDLEIY4x6Ac",
          plus_code: {
            compound_code: "MGHJ+7W St. Louis, MO, USA",
            global_code: "86CFMGHJ+7W",
          },
          types: [
            "establishment",
            "health",
            "insurance_agency",
            "point_of_interest",
          ],
        },
      ],
    },
    "23.3.0": "NMLS ID",
    "23.4.0": "WA",
    "23.5.0": "Contact",
    "23.5.1": "Jon Doe",
    "23.5.5": "Known Customer Service",
    "23.6.0": "Contact NMLS ID",
    "23.7.0": "Contact WA License ID",
    "23.8.0": "Email",
    "23.8.1": "jdoe@bank_bank.com",
    "23.8.5": "Closing@Known.com",
    "23.9.0": "Phone",
    "23.9.1": "(123) 456-7890",
    "23.9.5": "(800) 123-4567",
    "8.11.1.0": "",
    "8.11.1.1": "",
    "8.12.1.0": "",
    "8.12.1.1": "",
    "8.13.1.0": "",
    "8.13.1.1": "",
    "5.6.5.0.map": "Other",
    "3.2.map": "Refinance",
    "5.5.map": "Monthly",
    "5.6.2.map": "Monthly",
    "8.5.map": "HomeownersInsurancePremium",
    "8.6.map": "MortgageInsurancePremium",
    "8.7.map": "PrepaidInterest",
    CreatedDatetime: "2021-08-07T18:24:13-07:00",
  },
};

// GET ALL DATA
mock.onGet("/api/pdf/escrow").reply(200, data.contents);
