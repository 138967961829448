import mock from "../../mock";

const data = {
  contents: {
    id: "1234567890",
    file_name: {
      base: "01-Title.pdf",
      update: "01-Lender.pdf",
    },
    property_address: {
      subpremise: "",
      street_number: "2209",
      route: "Chadbourne Ave",
      neighborhood: "Regent",
      locality: "Madison",
      administrative_area_level_2: "Dane",
      administrative_area_level_1: "WI",
      country: "US",
      postal_code: "53726",
      postal_code_suffix: "3929",
      raw_address: "2209 Chadbourne Ave, Madison, WI 53726",
      geocode_result: [
        {
          address_components: [
            {
              long_name: "2209",
              short_name: "2209",
              types: ["street_number"],
            },
            {
              long_name: "Chadbourne Avenue",
              short_name: "Chadbourne Ave",
              types: ["route"],
            },
            {
              long_name: "Regent",
              short_name: "Regent",
              types: ["neighborhood", "political"],
            },
            {
              long_name: "Madison",
              short_name: "Madison",
              types: ["locality", "political"],
            },
            {
              long_name: "Dane County",
              short_name: "Dane County",
              types: ["administrative_area_level_2", "political"],
            },
            {
              long_name: "Wisconsin",
              short_name: "WI",
              types: ["administrative_area_level_1", "political"],
            },
            {
              long_name: "United States",
              short_name: "US",
              types: ["country", "political"],
            },
            {
              long_name: "53726",
              short_name: "53726",
              types: ["postal_code"],
            },
            {
              long_name: "3929",
              short_name: "3929",
              types: ["postal_code_suffix"],
            },
          ],
          formatted_address: "2209 Chadbourne Ave, Madison, WI 53726, USA",
          geometry: {
            bounds: {
              northeast: {
                lat: 43.0685908,
                lng: -89.4242666,
              },
              southwest: {
                lat: 43.0684541,
                lng: -89.4243812,
              },
            },
            location: {
              lat: 43.0685212,
              lng: -89.4243225,
            },
            location_type: "ROOFTOP",
            viewport: {
              northeast: {
                lat: 43.0698714302915,
                lng: -89.4229749197085,
              },
              southwest: {
                lat: 43.0671734697085,
                lng: -89.4256728802915,
              },
            },
          },
          place_id: "ChIJNVFpBu-sB4gR1e8Ps_98wig",
          types: ["premise"],
        },
      ],
    },
    diff: {
      25: [
        {
          25: {
            content: {
              base: "First Position Mortgage Lien to JP Morgan Chase Bank, NA",
              update: null,
            },
            bbox: {
              base: null,
              update: null,
            },
            page: {
              base: 3,
              update: 3,
            },
            selection: null,
          },
          value: {
            content: {
              base: "261410.25",
              update: null,
            },
            bbox: {
              base: null,
              update: null,
            },
            page: {
              base: 3,
              update: 3,
            },
            selection: null,
          },
        },
        {
          25: {
            content: {
              base: null,
              update: "JPMCB - HOME LENDING",
            },
            bbox: {
              base: null,
              update: null,
            },
            page: {
              base: 3,
              update: 3,
            },
            selection: null,
          },
          value: {
            content: {
              base: null,
              update: "261410.25",
            },
            bbox: {
              base: null,
              update: null,
            },
            page: {
              base: 3,
              update: 3,
            },
            selection: null,
          },
        },
      ],
      1.4: {
        content: {
          update: "Known  TITLE COMPANY",
          base: "Known Title Company",
        },
        bbox: {
          base: [121, 118, 199, 129],
          update: [118, 131, 210, 141],
        },
        page: {
          base: 1,
          update: 1,
        },
        selection: null,
      },
      3.2: {
        content: {
          update: "Refinance",
          base: "Loan Only/Refi - Standard",
        },
        bbox: {
          base: [444, 93, 536, 104],
          update: [474, 108, 511, 120],
        },
        page: {
          base: 1,
          update: 1,
        },
        selection: null,
      },
      "3.4.4": {
        content: {
          update: null,
          base: true,
        },
        bbox: {
          base: [470, 151, 479, 160],
          update: null,
        },
        page: {
          base: 1,
          update: null,
        },
        selection: null,
      },
      "6.1.1": {
        content: {
          update: "5337.91",
          base: "1159.40",
        },
        bbox: {
          base: [153, 630, 208, 646],
          update: [182, 688, 238, 708],
        },
        page: {
          base: 1,
          update: 1,
        },
        selection: null,
      },
      "6.1.2": {
        content: {
          update: "2615.55",
          base: "1159.40",
        },
        bbox: {
          base: [240, 625, 367, 650],
          update: [343, 690, 539, 704],
        },
        page: {
          base: 1,
          update: 1,
        },
        selection: null,
      },
      "6.1.3": {
        content: {
          update: "2722.36",
          base: "0.00",
        },
        bbox: {
          base: [240, 625, 367, 650],
          update: [343, 690, 539, 704],
        },
        page: {
          base: 1,
          update: 1,
        },
        selection: null,
      },
      "6.2.1": {
        content: {
          update: "1551.84",
          base: "5730.35",
        },
        bbox: {
          base: [153, 657, 208, 673],
          update: [182, 722, 238, 742],
        },
        page: {
          base: 1,
          update: 1,
        },
        selection: null,
      },
      "5.6.1": {
        content: {
          update: "651.28",
          base: "0.00",
        },
        bbox: {
          base: [181, 551, 206, 564],
          update: [193, 582, 239, 601],
        },
        page: {
          base: 1,
          update: 1,
        },
        selection: null,
      },
      "5.6.5.0": {
        content: {
          update: "Other: HOA Dues",
          base: "Other:",
        },
        bbox: {
          base: [284, 564, 311, 578],
          update: [291, 601, 366, 615],
        },
        page: {
          base: 1,
          update: 1,
        },
        selection: null,
      },
      "7.1.1": {
        content: {
          update: "579.15",
          base: "0.00",
        },
        bbox: {
          base: [350, 85, 369, 95],
          update: [424, 67, 452, 78],
        },
        page: {
          base: 2,
          update: 2,
        },
        selection: null,
      },
      "7.4.1": {
        content: {
          update: "2036.40",
          base: "0.00",
        },
        bbox: {
          base: [350, 112, 369, 122],
          update: [420, 159, 456, 170],
        },
        page: {
          base: 2,
          update: 2,
        },
        selection: null,
      },
      "7.6.1": {
        content: {
          update: null,
          base: "1159.40",
        },
        bbox: {
          base: [342, 139, 377, 150],
          update: null,
        },
        page: {
          base: 2,
          update: null,
        },
        selection: null,
      },
      "7.8.1": {
        content: {
          update: "2615.55",
          base: "1159.40",
        },
        bbox: {
          base: [342, 189, 377, 199],
          update: [420, 363, 456, 374],
        },
        page: {
          base: 2,
          update: 2,
        },
        selection: null,
      },
      "7.9.2": {
        content: {
          update: "2615.55",
          base: "1159.40",
        },
        bbox: {
          base: [325, 203, 358, 213],
          update: [404, 374, 436, 385],
        },
        page: {
          base: 2,
          update: 2,
        },
        selection: null,
      },
      "7.9.3": {
        content: {
          update: null,
          base: "0.00",
        },
        bbox: {
          base: [394, 203, 412, 213],
          update: null,
        },
        page: {
          base: 2,
          update: null,
        },
        selection: null,
      },
      "8.1.1": {
        content: {
          update: "140.00",
          base: "0.00",
        },
        bbox: {
          base: [350, 243, 369, 254],
          update: [424, 413, 452, 424],
        },
        page: {
          base: 2,
          update: 2,
        },
        selection: null,
      },
      "8.4.1": {
        content: {
          update: "201.24",
          base: "0.00",
        },
        bbox: {
          base: [350, 271, 369, 281],
          update: [424, 445, 452, 456],
        },
        page: {
          base: 2,
          update: 2,
        },
        selection: null,
      },
      8.5: {
        content: {
          update: "Homeowner's Insurance Premium (   mo.)",
          base: "Homeowner's Insurance Premium ( 12 mo.) to",
        },
        bbox: {
          base: [48, 284, 203, 294],
          update: [47, 456, 189, 467],
        },
        page: {
          base: 2,
          update: 2,
        },
        selection: null,
      },
      8.6: {
        content: {
          update: "Mortgage Insurance Premium (   mo.)",
          base: "Mortgage Insurance Premium ( 12 mo.)",
        },
        bbox: {
          base: [48, 295, 181, 305],
          update: [47, 466, 176, 477],
        },
        page: {
          base: 2,
          update: 2,
        },
        selection: null,
      },
      8.7: {
        content: {
          update: "Prepaid Interest ( $22.36 per day from 6/22/21 to 7/1/21 )",
          base: "Prepaid Interest (  per day from 6/22/2021 to 7/1/2021 )",
        },
        bbox: {
          base: [48, 306, 233, 316],
          update: [47, 476, 242, 487],
        },
        page: {
          base: 2,
          update: 2,
        },
        selection: null,
      },
      "8.5.1": {
        content: {
          update: null,
          base: "12",
        },
        bbox: {
          base: [48, 284, 203, 294],
          update: null,
        },
        page: {
          base: 2,
          update: null,
        },
        selection: null,
      },
      "8.6.1": {
        content: {
          update: null,
          base: "12",
        },
        bbox: {
          base: [48, 295, 181, 305],
          update: null,
        },
        page: {
          base: 2,
          update: null,
        },
        selection: null,
      },
      "8.10.1": {
        content: {
          update: "2381.12",
          base: "0.00",
        },
        bbox: {
          base: [350, 320, 369, 330],
          update: [420, 507, 456, 518],
        },
        page: {
          base: 2,
          update: 2,
        },
        selection: null,
      },
      "8.11.1": {
        content: {
          update: "76.75 per month for 4 mo.",
          base: "per month for 12 mo.",
        },
        bbox: {
          base: [48, 333, 205, 344],
          update: [172, 518, 263, 529],
        },
        page: {
          base: 2,
          update: 2,
        },
        selection: null,
      },
      "8.12.1": {
        content: {
          update: "per month for    mo.",
          base: "per month for 12 mo.",
        },
        bbox: {
          base: [48, 344, 193, 355],
          update: [196, 528, 262, 539],
        },
        page: {
          base: 2,
          update: 2,
        },
        selection: null,
      },
      "8.13.1": {
        content: {
          update: "518.53 per month for 4 mo.",
          base: "per month for 12 mo.",
        },
        bbox: {
          base: [48, 355, 185, 366],
          update: [168, 538, 263, 549],
        },
        page: {
          base: 2,
          update: 2,
        },
        selection: null,
      },
      "8.16.1": {
        content: {
          update: null,
          base: "0.00",
        },
        bbox: {
          base: [350, 380, 369, 391],
          update: null,
        },
        page: {
          base: 2,
          update: null,
        },
        selection: null,
      },
      "8.18.1": {
        content: {
          update: "2722.36",
          base: "0.00",
        },
        bbox: {
          base: [350, 408, 369, 418],
          update: [420, 691, 456, 702],
        },
        page: {
          base: 2,
          update: 2,
        },
        selection: null,
      },
      "8.19.2": {
        content: {
          update: "2722.36",
          base: "0.00",
        },
        bbox: {
          base: [340, 422, 358, 432],
          update: [404, 702, 436, 713],
        },
        page: {
          base: 2,
          update: 2,
        },
        selection: null,
      },
      "8.19.3": {
        content: {
          update: null,
          base: "0.00",
        },
        bbox: {
          base: [394, 422, 412, 432],
          update: null,
        },
        page: {
          base: 2,
          update: null,
        },
        selection: null,
      },
      "9.1.1": {
        content: {
          update: "5337.91",
          base: "1159.40",
        },
        bbox: {
          base: [342, 447, 377, 458],
          update: [420, 725, 456, 736],
        },
        page: {
          base: 2,
          update: 2,
        },
        selection: null,
      },
      "9.2.3": {
        content: {
          update: null,
          base: "0.00",
        },
        bbox: {
          base: [394, 462, 412, 472],
          update: null,
        },
        page: {
          base: 2,
          update: null,
        },
        selection: null,
      },
      "9.2.2": {
        content: {
          update: "5337.91",
          base: "1159.40",
        },
        bbox: {
          base: [325, 462, 358, 472],
          update: [404, 736, 436, 747],
        },
        page: {
          base: 2,
          update: 2,
        },
        selection: null,
      },
      "10.10.1": {
        content: {
          update: "268300.00",
          base: "0",
        },
        bbox: {
          base: [229, 154, 238, 165],
          update: [196, 606, 237, 617],
        },
        page: {
          base: 3,
          update: 3,
        },
        selection: null,
      },
      "10.1.1": {
        content: {
          update: "-7040.00",
          base: "0",
        },
        bbox: {
          base: [229, 166, 238, 177],
          update: [202, 622, 237, 633],
        },
        page: {
          base: 3,
          update: 3,
        },
        selection: null,
      },
      "10.1.2": {
        content: {
          update: "-5337.91",
          base: "-1159.40",
        },
        bbox: {
          base: [261, 166, 299, 177],
          update: [262, 622, 297, 633],
        },
        page: {
          base: 3,
          update: 3,
        },
        selection: null,
      },
      "10.1.3": {
        content: {
          update: "YES",
          base: "NO",
        },
        bbox: {
          base: [299, 166, 317, 177],
          update: [305, 618, 319, 629],
        },
        page: {
          base: 3,
          update: 3,
        },
        selection: null,
      },
      "10.11.1": {
        content: {
          update: "-261278.00",
          base: "0",
        },
        bbox: {
          base: [229, 188, 238, 199],
          update: [194, 672, 236, 683],
        },
        page: {
          base: 3,
          update: 3,
        },
        selection: null,
      },
      "10.11.3": {
        content: {
          update: "YES",
          base: "NO",
        },
        bbox: {
          base: [299, 188, 317, 199],
          update: [306, 670, 319, 681],
        },
        page: {
          base: 3,
          update: 3,
        },
        selection: null,
      },
      "10.9.1": {
        content: {
          update: "18.00",
          base: "0",
        },
        bbox: {
          base: [231, 201, 240, 212],
          update: [212, 685, 237, 696],
        },
        page: {
          base: 3,
          update: 3,
        },
        selection: null,
      },
      "10.9.2": {
        content: {
          update: "1551.84",
          base: "5730.35",
        },
        bbox: {
          base: [265, 201, 301, 212],
          update: [261, 685, 297, 696],
        },
        page: {
          base: 3,
          update: 3,
        },
        selection: null,
      },
      17.5: {
        content: {
          update: "No",
          base: "Accept",
        },
        bbox: {
          base: [51, 453, 288, 462],
          update: [42, 502, 190, 513],
        },
        page: {
          base: 4,
          update: 4,
        },
        selection: null,
      },
      17.6: {
        street_number: {
          content: {
            base: "2209",
            update: "9",
          },
          bbox: {
            base: [37, 93, 141, 603],
            update: [36, 89, 175, 612],
          },
          page: {
            base: 4,
            update: 4,
          },
          selection: null,
        },
        neighborhood: {
          content: {
            update: null,
            base: "Regent",
          },
          bbox: {
            base: [37, 93, 141, 603],
            update: null,
          },
          page: {
            base: 4,
            update: 4,
          },
          selection: null,
        },
        administrative_area_level_2: {
          content: {
            update: null,
            base: "Dane",
          },
          bbox: {
            base: [37, 93, 141, 603],
            update: null,
          },
          page: {
            base: 4,
            update: 4,
          },
          selection: null,
        },
        administrative_area_level_1: {
          content: {
            base: "WI",
            update: "I",
          },
          bbox: {
            base: [37, 93, 141, 603],
            update: [36, 89, 175, 612],
          },
          page: {
            base: 4,
            update: 4,
          },
          selection: null,
        },
        country: {
          content: {
            update: null,
            base: "US",
          },
          bbox: {
            base: [37, 93, 141, 603],
            update: null,
          },
          page: {
            base: 4,
            update: 4,
          },
          selection: null,
        },
        postal_code_suffix: {
          content: {
            update: null,
            base: "3929",
          },
          bbox: {
            base: [37, 93, 141, 603],
            update: null,
          },
          page: {
            base: 4,
            update: 4,
          },
          selection: null,
        },
        subpremise: {
          content: {
            base: null,
            update: "UNIT 2 0 W 2",
          },
          bbox: {
            base: null,
            update: [36, 89, 175, 612],
          },
          page: {
            base: 4,
            update: 4,
          },
          selection: null,
        },
        individual: {
          content: {
            base: null,
            update: [
              {
                first_name: "2",
                last_name: "2",
                middle_name: "0 , ,",
              },
            ],
          },
          bbox: {
            base: null,
            update: [36, 89, 175, 612],
          },
          page: {
            base: 4,
            update: 4,
          },
          selection: null,
        },
      },
      18.4: {
        content: {
          update: "2381.12",
          base: "0.00",
        },
        bbox: {
          base: [403, 309, 423, 318],
          update: [401, 290, 433, 301],
        },
        page: {
          base: 4,
          update: 4,
        },
        selection: null,
      },
      18.5: {
        content: {
          update: "595.28",
          base: "0.00",
        },
        bbox: {
          base: [403, 336, 423, 345],
          update: [406, 324, 433, 335],
        },
        page: {
          base: 4,
          update: 4,
        },
        selection: null,
      },
      "18.3.1": {
        content: {
          update: "HOA Dues",
          base: "Property Taxes; Homeowner's Insurance",
        },
        bbox: {
          base: [427, 261, 516, 281],
          update: [438, 252, 471, 263],
        },
        page: {
          base: 4,
          update: 4,
        },
        selection: null,
      },
      "18.1.1": {
        content: {
          update: null,
          base: "WillNot",
        },
        bbox: {
          base: [317, 363, 326, 372],
          update: null,
        },
        page: {
          base: 4,
          update: null,
        },
        selection: null,
      },
      21.1: {
        content: {
          update: "410036.61",
          base: "0.00",
        },
        bbox: {
          base: [279, 57, 304, 68],
          update: [252, 68, 298, 81],
        },
        page: {
          base: 5,
          update: 5,
        },
        selection: null,
      },
      21.2: {
        content: {
          update: "140272.61",
          base: "0.00",
        },
        bbox: {
          base: [279, 110, 304, 121],
          update: [252, 105, 298, 118],
        },
        page: {
          base: 5,
          update: 5,
        },
        selection: null,
      },
      21.3: {
        content: {
          update: "266947.21",
          base: "0.00",
        },
        bbox: {
          base: [279, 142, 304, 153],
          update: [253, 141, 298, 153],
        },
        page: {
          base: 5,
          update: 5,
        },
        selection: null,
      },
      21.4: {
        content: {
          update: "3.04",
          base: "0",
        },
        bbox: {
          base: [290, 174, 304, 185],
          update: [273, 183, 298, 196],
        },
        page: {
          base: 5,
          update: 5,
        },
        selection: null,
      },
      21.5: {
        content: {
          update: "51.853",
          base: "0",
        },
        bbox: {
          base: [290, 216, 304, 228],
          update: [264, 227, 298, 240],
        },
        page: {
          base: 5,
          update: 5,
        },
        selection: null,
      },
      "23.1.5": {
        content: {
          update: " TITLE COMPANY Known",
          base: "Known Title Company",
        },
        bbox: {
          base: [421, 435, 499, 444],
          update: [457, 433, 457, 444],
        },
        page: {
          base: 5,
          update: 5,
        },
        selection: null,
      },
      "23.2.5": {
        street_number: {
          content: {
            update: null,
            base: "12655",
          },
          bbox: {
            base: [421, 449, 502, 467],
            update: null,
          },
          page: {
            base: 5,
            update: 5,
          },
          selection: null,
        },
        route: {
          content: {
            update: null,
            base: "Olive Blvd",
          },
          bbox: {
            base: [421, 449, 502, 467],
            update: null,
          },
          page: {
            base: 5,
            update: 5,
          },
          selection: null,
        },
        locality: {
          content: {
            update: null,
            base: "St. Louis",
          },
          bbox: {
            base: [421, 449, 502, 467],
            update: null,
          },
          page: {
            base: 5,
            update: 5,
          },
          selection: null,
        },
        administrative_area_level_2: {
          content: {
            update: null,
            base: "St Louis",
          },
          bbox: {
            base: [421, 449, 502, 467],
            update: null,
          },
          page: {
            base: 5,
            update: 5,
          },
          selection: null,
        },
        administrative_area_level_1: {
          content: {
            update: null,
            base: "MO",
          },
          bbox: {
            base: [421, 449, 502, 467],
            update: null,
          },
          page: {
            base: 5,
            update: 5,
          },
          selection: null,
        },
        country: {
          content: {
            update: null,
            base: "US",
          },
          bbox: {
            base: [421, 449, 502, 467],
            update: null,
          },
          page: {
            base: 5,
            update: 5,
          },
          selection: null,
        },
        postal_code: {
          content: {
            update: null,
            base: "63141",
          },
          bbox: {
            base: [421, 449, 502, 467],
            update: null,
          },
          page: {
            base: 5,
            update: 5,
          },
          selection: null,
        },
      },
      "23.5.5": {
        content: {
          update: "CLOSING DEPT",
          base: "Known Customer Service",
        },
        bbox: {
          base: [421, 508, 511, 517],
          update: [433, 506, 483, 517],
        },
        page: {
          base: 5,
          update: 5,
        },
        selection: null,
      },
      "23.8.1": {
        content: {
          update: "@bank .com jdoe _bank",
          base: "jdoe@bank_bank.com",
        },
        bbox: {
          base: [109, 558, 188, 567],
          update: [155, 554, 198, 565],
        },
        page: {
          base: 5,
          update: 5,
        },
        selection: null,
      },
      "23.8.5": {
        content: {
          update: "CLOSING@ .COM Known",
          base: "Closing@Known.com",
        },
        bbox: {
          base: [421, 558, 497, 567],
          update: [433, 554, 493, 565],
        },
        page: {
          base: 5,
          update: 5,
        },
        selection: null,
      },
      "23.9.5": {
        content: {
          update: "800- - 6 123 45 7",
          base: "(800) 123-4567",
        },
        bbox: {
          base: [421, 573, 475, 582],
          update: [433, 579, 479, 590],
        },
        page: {
          base: 5,
          update: 5,
        },
        selection: null,
      },
      1.7: {
        content: {
          base: null,
          update: "695000",
        },
        bbox: {
          base: null,
          update: [36, 174, 155, 190],
        },
        page: {
          base: null,
          update: 1,
        },
        selection: null,
      },
      3.1: {
        content: {
          base: null,
          update: "30",
        },
        bbox: {
          base: null,
          update: [474, 96, 504, 109],
        },
        page: {
          base: null,
          update: 1,
        },
        selection: null,
      },
      3.3: {
        content: {
          base: null,
          update: "Fixed Rate",
        },
        bbox: {
          base: null,
          update: [474, 118, 512, 131],
        },
        page: {
          base: null,
          update: 1,
        },
        selection: null,
      },
      "3.4.1": {
        content: {
          base: null,
          update: true,
        },
        bbox: {
          base: null,
          update: [480, 143, 486, 149],
        },
        page: {
          base: null,
          update: 1,
        },
        selection: null,
      },
      "4.2.1": {
        content: {
          base: null,
          update: "3",
        },
        bbox: {
          base: null,
          update: [187, 246, 206, 265],
        },
        page: {
          base: null,
          update: 1,
        },
        selection: null,
      },
      "4.3.1": {
        content: {
          base: null,
          update: "1131.16",
        },
        bbox: {
          base: null,
          update: [187, 287, 243, 306],
        },
        page: {
          base: null,
          update: 1,
        },
        selection: null,
      },
      "5.1.1": {
        content: {
          base: null,
          update: "Years 1-30",
        },
        bbox: {
          base: null,
          update: [350, 439, 397, 453],
        },
        page: {
          base: null,
          update: 1,
        },
        selection: null,
      },
      "5.2.1": {
        content: {
          base: null,
          update: "1131.16",
        },
        bbox: {
          base: null,
          update: [354, 459, 394, 473],
        },
        page: {
          base: null,
          update: 1,
        },
        selection: null,
      },
      "5.2.2": {
        content: {
          base: null,
          update: "1131.16",
        },
        bbox: {
          base: null,
          update: [354, 459, 394, 473],
        },
        page: {
          base: null,
          update: 1,
        },
        selection: null,
      },
      "5.3.1": {
        content: {
          base: null,
          update: "0",
        },
        bbox: {
          base: null,
          update: [377, 479, 382, 493],
        },
        page: {
          base: null,
          update: 1,
        },
        selection: null,
      },
      "5.4.1": {
        content: {
          base: null,
          update: "595.28",
        },
        bbox: {
          base: null,
          update: [366, 499, 394, 513],
        },
        page: {
          base: null,
          update: 1,
        },
        selection: null,
      },
      "5.5.1": {
        content: {
          base: null,
          update: "1726.44",
        },
        bbox: {
          base: null,
          update: [345, 531, 401, 550],
        },
        page: {
          base: null,
          update: 1,
        },
        selection: null,
      },
      "5.6.3.1": {
        content: {
          base: null,
          update: "YES",
        },
        bbox: {
          base: null,
          update: [473, 572, 490, 586],
        },
        page: {
          base: null,
          update: 1,
        },
        selection: null,
      },
      "5.6.4.1": {
        content: {
          base: null,
          update: "YES",
        },
        bbox: {
          base: null,
          update: [473, 586, 490, 600],
        },
        page: {
          base: null,
          update: 1,
        },
        selection: null,
      },
      "5.6.5.1": {
        content: {
          base: null,
          update: "NO",
        },
        bbox: {
          base: null,
          update: [473, 601, 487, 615],
        },
        page: {
          base: null,
          update: 1,
        },
        selection: null,
      },
      "5.6.3": {
        content: {
          base: null,
          update: true,
        },
        bbox: {
          base: null,
          update: [285, 576, 292, 583],
        },
        page: {
          base: null,
          update: 1,
        },
        selection: null,
      },
      "5.6.4": {
        content: {
          base: null,
          update: true,
        },
        bbox: {
          base: null,
          update: [285, 590, 292, 597],
        },
        page: {
          base: null,
          update: 1,
        },
        selection: null,
      },
      "5.6.5": {
        content: {
          base: null,
          update: true,
        },
        bbox: {
          base: null,
          update: [285, 605, 292, 612],
        },
        page: {
          base: null,
          update: 1,
        },
        selection: null,
      },
      "5.1.1.1": {
        content: {
          base: null,
          update: "1",
        },
        bbox: {
          base: null,
          update: [350, 439, 397, 453],
        },
        page: {
          base: null,
          update: 1,
        },
        selection: null,
      },
      "5.1.1.2": {
        content: {
          base: null,
          update: "30",
        },
        bbox: {
          base: null,
          update: [350, 439, 397, 453],
        },
        page: {
          base: null,
          update: 1,
        },
        selection: null,
      },
      "7.2.1": {
        content: {
          base: null,
          update: "0.05",
        },
        bbox: {
          base: null,
          update: [47, 78, 157, 89],
        },
        page: {
          base: null,
          update: 2,
        },
        selection: null,
      },
      7.2: {
        "7.2.2": {
          content: {
            base: null,
            update: "134.15",
          },
          bbox: {
            base: null,
            update: [410, 78, 436, 89],
          },
          page: {
            base: null,
            update: 2,
          },
          selection: null,
        },
      },
      7.3: [
        {
          "7.3.2": {
            content: {
              base: null,
              update: "445.00",
            },
            bbox: {
              base: null,
              update: [410, 88, 436, 99],
            },
            page: {
              base: null,
              update: 2,
            },
            selection: null,
          },
          7.3: {
            content: {
              base: null,
              update: "Underwriting Fees",
            },
            bbox: {
              base: null,
              update: [47, 88, 114, 99],
            },
            page: {
              base: null,
              update: 2,
            },
            selection: null,
          },
        },
      ],
      7.5: [
        {
          "7.5.2": {
            content: {
              base: null,
              update: "835.00",
            },
            bbox: {
              base: null,
              update: [410, 170, 436, 181],
            },
            page: {
              base: null,
              update: 2,
            },
            selection: null,
          },
          7.5: {
            content: {
              base: null,
              update: "Appraisal Fee",
            },
            bbox: {
              base: null,
              update: [47, 170, 97, 181],
            },
            page: {
              base: null,
              update: 2,
            },
            selection: null,
          },
          "7.5.1": {
            content: {
              base: null,
              update: "to BROAD STRET VALUATIONS",
            },
            bbox: {
              base: null,
              update: [196, 170, 297, 181],
            },
            page: {
              base: null,
              update: 2,
            },
            selection: null,
          },
        },
        {
          "7.5.2": {
            content: {
              base: null,
              update: "29.00",
            },
            bbox: {
              base: null,
              update: [414, 180, 436, 191],
            },
            page: {
              base: null,
              update: 2,
            },
            selection: null,
          },
          7.5: {
            content: {
              base: null,
              update: "Credit Report",
            },
            bbox: {
              base: null,
              update: [47, 180, 97, 191],
            },
            page: {
              base: null,
              update: 2,
            },
            selection: null,
          },
          "7.5.1": {
            content: {
              base: null,
              update: "to Factual Data",
            },
            bbox: {
              base: null,
              update: [196, 180, 247, 191],
            },
            page: {
              base: null,
              update: 2,
            },
            selection: null,
          },
        },
        {
          "7.5.2": {
            content: {
              base: null,
              update: "13.00",
            },
            bbox: {
              base: null,
              update: [414, 190, 436, 201],
            },
            page: {
              base: null,
              update: 2,
            },
            selection: null,
          },
          7.5: {
            content: {
              base: null,
              update: "Flood Certification",
            },
            bbox: {
              base: null,
              update: [47, 190, 114, 201],
            },
            page: {
              base: null,
              update: 2,
            },
            selection: null,
          },
          "7.5.1": {
            content: {
              base: null,
              update: "to CoreLogic Flood Services",
            },
            bbox: {
              base: null,
              update: [196, 190, 288, 201],
            },
            page: {
              base: null,
              update: 2,
            },
            selection: null,
          },
        },
      ],
      "8.2.1": {
        Mortgage: {
          content: {
            base: null,
            update: "140.00",
          },
          bbox: {
            base: null,
            update: [196, 424, 294, 435],
          },
          page: {
            base: 2,
            update: 2,
          },
          selection: null,
        },
      },
      "8.2.2": {
        content: {
          base: null,
          update: "140.00",
        },
        bbox: {
          base: null,
          update: [410, 424, 436, 435],
        },
        page: {
          base: null,
          update: 2,
        },
        selection: null,
      },
      8.2: {
        content: {
          base: null,
          update: "Recording Fees",
        },
        bbox: {
          base: null,
          update: [47, 424, 104, 435],
        },
        page: {
          base: null,
          update: 2,
        },
        selection: null,
      },
      "8.7.2": {
        content: {
          base: null,
          update: "201.24",
        },
        bbox: {
          base: null,
          update: [410, 476, 436, 487],
        },
        page: {
          base: null,
          update: 2,
        },
        selection: null,
      },
      8.8: {
        content: {
          base: null,
          update: "Property Taxes (   mo.)",
        },
        bbox: {
          base: null,
          update: [47, 486, 126, 497],
        },
        page: {
          base: null,
          update: 2,
        },
        selection: null,
      },
      "8.7.1": {
        content: {
          base: null,
          update: "22.36",
        },
        bbox: {
          base: null,
          update: [47, 476, 242, 487],
        },
        page: {
          base: null,
          update: 2,
        },
        selection: null,
      },
      "8.11.2": {
        content: {
          base: null,
          update: "307.00",
        },
        bbox: {
          base: null,
          update: [410, 518, 436, 529],
        },
        page: {
          base: null,
          update: 2,
        },
        selection: null,
      },
      "8.13.2": {
        content: {
          base: null,
          update: "2074.12",
        },
        bbox: {
          base: null,
          update: [404, 538, 436, 549],
        },
        page: {
          base: null,
          update: 2,
        },
        selection: null,
      },
      "8.15.2": {
        content: {
          base: null,
          update: "0.00",
        },
        bbox: {
          base: null,
          update: [418, 588, 436, 599],
        },
        page: {
          base: null,
          update: 2,
        },
        selection: null,
      },
      10.3: {
        content: {
          base: null,
          update: "5337.91",
        },
        bbox: {
          base: null,
          update: [489, 707, 521, 718],
        },
        page: {
          base: null,
          update: 3,
        },
        selection: null,
      },
      "10.1.4": {
        content: {
          base: null,
          update:
            " See Total Loan Costs (D) Total Loan Costs (D) and Total Other Costs (I) Total Other Costs (I).",
        },
        bbox: {
          base: null,
          update: [330, 618, 476, 628],
        },
        page: {
          base: null,
          update: 3,
        },
        selection: null,
      },
      "10.11.4": {
        content: {
          base: null,
          update: " See Payoffs and Payments (K) Payoffs and Payments (K).",
        },
        bbox: {
          base: null,
          update: [330, 670, 433, 681],
        },
        page: {
          base: null,
          update: 3,
        },
        selection: null,
      },
      "10.3.2": {
        content: {
          base: null,
          update: "5,337.91",
        },
        bbox: {
          base: null,
          update: [489, 707, 521, 718],
        },
        page: {
          base: null,
          update: 3,
        },
        selection: null,
      },
      "10.9.3": {
        content: {
          base: null,
          update: "From",
        },
        bbox: {
          base: null,
          update: [183, 699, 192, 708],
        },
        page: {
          base: null,
          update: 3,
        },
        selection: null,
      },
      "17.3.1": {
        content: {
          base: null,
          update: "15",
        },
        bbox: {
          base: null,
          update: [35, 235, 230, 257],
        },
        page: {
          base: null,
          update: 4,
        },
        selection: null,
      },
      "17.3.3": {
        content: {
          base: null,
          update: "5",
        },
        bbox: {
          base: null,
          update: [35, 235, 230, 257],
        },
        page: {
          base: null,
          update: 4,
        },
        selection: null,
      },
      18.1: {
        content: {
          base: null,
          update: "Yes",
        },
        bbox: {
          base: null,
          update: [315, 113, 322, 120],
        },
        page: {
          base: null,
          update: 4,
        },
        selection: null,
      },
      18.2: {
        content: {
          base: null,
          update: "6548.08",
        },
        bbox: {
          base: null,
          update: [401, 197, 433, 208],
        },
        page: {
          base: null,
          update: 4,
        },
        selection: null,
      },
      18.3: {
        content: {
          base: null,
          update: "616.00",
        },
        bbox: {
          base: null,
          update: [406, 237, 433, 248],
        },
        page: {
          base: null,
          update: 4,
        },
        selection: null,
      },
      "18.2.1": {
        content: {
          base: null,
          update: "Property Taxes, Homeowner's Insurance",
        },
        bbox: {
          base: null,
          update: [438, 210, 565, 221],
        },
        page: {
          base: null,
          update: 4,
        },
        selection: null,
      },
      "23.3.1": {
        content: {
          base: null,
          update: "123456",
        },
        bbox: {
          base: null,
          update: [140, 475, 165, 485],
        },
        page: {
          base: null,
          update: 5,
        },
        selection: null,
      },
      "23.4.5": {
        content: {
          base: null,
          update: "1234",
        },
        bbox: {
          base: null,
          update: [433, 491, 450, 501],
        },
        page: {
          base: null,
          update: 5,
        },
        selection: null,
      },
      "23.6.1": {
        content: {
          base: null,
          update: "123456",
        },
        bbox: {
          base: null,
          update: [140, 523, 165, 533],
        },
        page: {
          base: null,
          update: 5,
        },
        selection: null,
      },
      "23.2.5.unparsed_legal": {
        content: {
          base: null,
          update: "  12655 Olive Blvd.  Saint L , MO 63 ouis 141",
        },
        bbox: {
          base: null,
          update: null,
        },
        page: {
          base: null,
          update: null,
        },
        selection: null,
      },
      "8.11.1.0": {
        content: {
          base: null,
          update: "76.75",
        },
        bbox: {
          base: null,
          update: [172, 518, 263, 529],
        },
        page: {
          base: null,
          update: 2,
        },
        selection: null,
      },
      "8.11.1.1": {
        content: {
          base: null,
          update: "4",
        },
        bbox: {
          base: null,
          update: [172, 518, 263, 529],
        },
        page: {
          base: null,
          update: 2,
        },
        selection: null,
      },
      "8.13.1.0": {
        content: {
          base: null,
          update: "518.53",
        },
        bbox: {
          base: null,
          update: [168, 538, 263, 549],
        },
        page: {
          base: null,
          update: 2,
        },
        selection: null,
      },
      "8.13.1.1": {
        content: {
          base: null,
          update: "4",
        },
        bbox: {
          base: null,
          update: [168, 538, 263, 549],
        },
        page: {
          base: null,
          update: 2,
        },
        selection: null,
      },
    },
  },
};

// GET ALL DATA
mock.onGet("/api/pdf/diff").reply(200, data.contents);
