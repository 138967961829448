import mock from "../mock";

const data = {
  contents: [
    {
      id: "1.7.0",
      reference: "Sale Price",
      value: "$654,859.13",
    },
    {
      id: 2,
      reference: "4.1.2",
      value: "$199,859.13",
    },
  ],
};

// GET ALL DATA
mock.onGet("/api/pdf/data-value-new").reply(200, data.contents);
