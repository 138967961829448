// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import loading from "./loading";
import searchPdf from "./searchPdf";
import selectDocuments from "@src/views/pages/select-documents/store/reducers";
import pdf from "@src/views/pages/pdf/store/reducers";
import buttonM from "@src/views/components/pspdfkit/button-m/store/reducers";
import submissions from "@src/views/pages/submission/store/reducers";
const rootReducer = combineReducers({
  navbar,
  layout,
  loading,
  searchPdf,
  selectDocuments,
  pdf,
  buttonM,
  submissions,
});

export default rootReducer;
