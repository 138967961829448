import icon from "@src/assets/images/pages/loading.svg";

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner vh-100">
      <div className="loading">
        <img className="loading-icon" src={icon} />
      </div>
    </div>
  );
};

export default SpinnerComponent;
