import React from "react";
import ReactDOM from "react-dom";
import Root from "./Root";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [new BrowserTracing()],
    release: `${process.env.npm_package_name}@${process.env.npm_package_version}`,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });

  // Mute Console Logs in production environments
  console.log = function () {};
  console.info = function () {};
  console.warn = function () {};
  console.error = function () {};
}
ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
