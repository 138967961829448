import mock from "../../mock";

const data = {
  contents: "Successfully",
};

mock.onPost("/analyze").reply((request) => {
  if (request) {
    return [200, data.contents];
  }
  return [400, "Unsuccessfully"];
});
