const initialState = {
  data: null,
  sectionName: null,
  sectionDataTable: null,
  searchedSectionDataTable: null,
  isSearch: false,
  selectedSection: [],
  modalMismoShowModal: false,
  modalMismoCurrentItem: null,
  modalMismoTitle: "",
};

const buttonMReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SETTLEMENT_AND_MISMO_TYPE":
      return { ...state, data: action.data };
    case "SET_SECTION_NAME":
      return { ...state, sectionName: action.data };
    case "SET_SECTION_DATATABLE":
      return { ...state, sectionDataTable: action.data };
    case "SET_SEARCHED_SECTION_DATATABLE":
      return { ...state, searchedSectionDataTable: action.data };
    case "SET_IS_SEARCH_SECTION_DATA_TABLE":
      return { ...state, isSearch: action.data };
    case "SET_SELECTED_SECTION":
      return {
        ...state,
        selectedSection: action.data,
      };
    case "SET_MISMO_CURRENT_ITEM":
      return {
        ...state,
        modalMismoCurrentItem: action.data,
      };
    case "SET_MISMO_TITLE":
      return {
        ...state,
        modalMismoTitle: action.data,
      };
    case "SET_SHOW_MISMO_MODAL":
      return {
        ...state,
        modalMismoShowModal: action.data,
      };
    default:
      return state;
  }
};

export default buttonMReducer;
