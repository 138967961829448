import mock from "../../mock";

const data = {
  contents: {
    "1.7.0": [37.0, 177.57858276367188, 114.7750015258789, 187.9060821533203],
    1.1: [
      121.74400329589844, 79.4000015258789, 157.31199645996094,
      90.39199829101562,
    ],
    1.2: [
      121.74400329589844, 93.8000259399414, 157.31199645996094,
      104.79202270507812,
    ],
    1.3: [
      121.74400329589844, 106.8320083618164, 157.31199645996094,
      117.82400512695312,
    ],
    1.4: [
      121.74400329589844, 118.9999771118164, 199.86900329589844,
      129.99197387695312,
    ],
    1.5: [
      121.74400329589844, 136.99996948242188, 188.84800720214844,
      147.99197387695312,
    ],
    1.6: [
      121.74400329589844, 151.55398559570312, 169.9939727783203,
      177.0179901123047,
    ],
    1.7: [],
    2.1: [
      260.427001953125, 80.47601318359375, 329.2041931152344, 107.6427001953125,
    ],
    2.2: [],
    2.3: [
      260.427001953125, 121.0829849243164, 278.6510009765625,
      132.07498168945312,
    ],
    "3.1.0": [
      444.23199462890625, 78.9679946899414, 467.239990234375, 89.95999145507812,
    ],
    3.1: [
      444.23199462890625, 78.9679946899414, 467.239990234375, 89.95999145507812,
    ],
    3.2: [
      444.23199462890625, 93.3680191040039, 536.4639892578125,
      104.36001586914062,
    ],
    3.3: [],
    3.5: [
      444.23199462890625, 162.63198852539062, 488.71197509765625,
      173.62399291992188,
    ],
    3.6: [],
    "3.4.4": [
      470.5119934082031, 151.16400146484375, 479.5119934082031,
      160.16400146484375,
    ],
    4.3: [
      40.79199981689453, 276.58477783203125, 167.31199645996094,
      290.35479736328125,
    ],
    "4.1.1": [183.0, 223.14599609375, 224.6899871826172, 236.88600158691406],
    "4.1.2": [
      273.22998046875, 223.1959991455078, 288.6199951171875, 236.96600341796875,
    ],
    "4.2.2": [
      273.22998046875, 244.9428253173828, 288.6199951171875, 258.71282958984375,
    ],
    "4.3.2": [
      273.23199462890625, 266.6839599609375, 288.62200927734375,
      280.4539794921875,
    ],
    "4.4.2": [
      273.23199462890625, 341.0867919921875, 288.62200927734375,
      354.8568115234375,
    ],
    "4.5.2": [
      273.2340087890625, 362.8330993652344, 288.6240234375, 376.6031188964844,
    ],
    "6.1.1": [
      153.27999877929688, 630.0691528320312, 208.79200744628906,
      646.5932006835938,
    ],
    "6.1.2": [
      240.3280029296875, 625.2922973632812, 367.21600341796875,
      650.0525512695312,
    ],
    "6.1.3": [
      240.3280029296875, 625.2922973632812, 367.21600341796875,
      650.0525512695312,
    ],
    "6.1.4": [
      240.3280029296875, 625.2922973632812, 367.21600341796875,
      650.0525512695312,
    ],
    "6.2.1": [
      153.27999877929688, 657.28515625, 208.79200744628906, 673.8092041015625,
    ],
    "6.2.2": [
      277.8399963378906, 668.5640258789062, 286.8399963378906,
      677.5640258789062,
    ],
    5.5: [47.0, 494.15179443359375, 87.8499984741211, 519.2418212890625],
    "5.6.1": [181.0, 551.061767578125, 206.00999450683594, 564.8017578125],
    "5.6.2": [181.0, 564.3818359375, 216.989990234375, 578.121826171875],
    "5.6.5.0": [
      284.0320129394531, 564.4171142578125, 311.7919616699219,
      578.1571044921875,
    ],
    "7.1.1": [
      350.2405090332031, 85.4601058959961, 369.748046875, 95.78760528564453,
    ],
    "7.2.1": [
      48.340003967285156, 98.8865966796875, 133.97499084472656,
      109.19159698486328,
    ],
    "7.2.0": [
      48.340003967285156, 98.8865966796875, 133.97499084472656,
      109.19159698486328,
    ],
    "7.4.1": [
      350.2405090332031, 112.6380844116211, 369.748046875, 122.96558380126953,
    ],
    "7.6.1": [
      342.6416015625, 139.81613159179688, 377.336669921875, 150.1436309814453,
    ],
    7.7: [
      {
        "7.7.2": [
          331.8924865722656, 153.24261474609375, 358.9900817871094,
          163.547607421875,
        ],
        7.7: [
          51.402000427246094, 153.24249267578125, 235.33599853515625,
          163.5474853515625,
        ],
      },
      {
        "7.7.2": [
          331.8924865722656, 164.33062744140625, 358.9900817871094,
          174.6356201171875,
        ],
        7.7: [
          51.402000427246094, 164.33050537109375, 238.0240020751953,
          174.635498046875,
        ],
      },
      {
        "7.7.2": [
          336.0625, 175.4185791015625, 358.9900817871094, 185.72357177734375,
        ],
        7.7: [
          51.402000427246094, 175.41851806640625, 235.510986328125,
          185.7235107421875,
        ],
      },
    ],
    "7.8.1": [
      342.6416015625, 189.17013549804688, 377.336669921875, 199.4976348876953,
    ],
    "7.9.2": [
      325.6390686035156, 203.53265380859375, 358.98419189453125,
      213.837646484375,
    ],
    "7.9.3": [
      394.2364501953125, 203.53265380859375, 412.9940185546875,
      213.837646484375,
    ],
    "8.1.1": [
      350.2405090332031, 243.85208129882812, 369.748046875, 254.17958068847656,
    ],
    "8.2.1": [
      48.340003967285156, 257.27862548828125, 233.95750427246094,
      267.5836181640625,
    ],
    "8.4.1": [
      350.2405090332031, 271.0301208496094, 369.748046875, 281.35760498046875,
    ],
    8.5: [
      48.340003967285156, 284.45660400390625, 203.96498107910156,
      294.7615966796875,
    ],
    8.6: [
      48.340003967285156, 295.5445861816406, 181.802490234375,
      305.8495788574219,
    ],
    8.7: [
      48.340003967285156, 306.6326904296875, 233.82997131347656,
      316.93768310546875,
    ],
    "8.5.1": [
      48.340003967285156, 284.45660400390625, 203.96498107910156,
      294.7615966796875,
    ],
    "8.6.1": [
      48.340003967285156, 295.5445861816406, 181.802490234375,
      305.8495788574219,
    ],
    "8.7.1": [
      48.340003967285156, 306.6326904296875, 233.82997131347656,
      316.93768310546875,
    ],
    "8.10.1": [
      350.2405090332031, 320.38409423828125, 369.748046875, 330.7115783691406,
    ],
    8.11: [
      48.340003967285156, 333.8106994628906, 205.08998107910156,
      344.1156921386719,
    ],
    "8.11.1": [
      48.340003967285156, 333.8106994628906, 205.08998107910156,
      344.1156921386719,
    ],
    8.12: [
      48.340003967285156, 344.8987121582031, 193.1499786376953,
      355.2037048339844,
    ],
    "8.12.1": [
      48.340003967285156, 344.8987121582031, 193.1499786376953,
      355.2037048339844,
    ],
    8.13: [
      48.340003967285156, 355.9866027832031, 185.2449951171875,
      366.2915954589844,
    ],
    "8.13.1": [
      48.340003967285156, 355.9866027832031, 185.2449951171875,
      366.2915954589844,
    ],
    8.15: [
      48.340003967285156, 367.0745849609375, 125.51498413085938,
      377.37957763671875,
    ],
    "8.16.1": [
      350.2405090332031, 380.82611083984375, 369.748046875, 391.1535949707031,
    ],
    "8.18.1": [
      350.2405090332031, 408.0041198730469, 369.748046875, 418.33160400390625,
    ],
    "8.19.2": [
      340.2354736328125, 422.3666076660156, 358.9930419921875,
      432.6716003417969,
    ],
    "8.19.3": [
      394.23553466796875, 422.3666076660156, 412.99310302734375,
      432.6716003417969,
    ],
    "9.1.1": [
      342.6416015625, 447.99810791015625, 377.336669921875, 458.3255920410156,
    ],
    "9.2.3": [
      394.2364501953125, 462.360595703125, 412.9940185546875,
      472.66558837890625,
    ],
    "9.2.2": [
      325.6390686035156, 462.360595703125, 358.98419189453125,
      472.66558837890625,
    ],
    "10.10.1": [
      229.30160522460938, 154.86862182617188, 238.19760131835938,
      165.86062622070312,
    ],
    "10.10.2": [
      254.91200256347656, 154.86862182617188, 299.3760070800781,
      165.86062622070312,
    ],
    "10.10.3": [
      299.3760070800781, 154.90863037109375, 317.7096252441406,
      165.92462158203125,
    ],
    "10.1.1": [
      229.30160522460938, 166.20626831054688, 238.19760131835938,
      177.19827270507812,
    ],
    "10.1.2": [
      261.1463928222656, 166.20626831054688, 299.3783874511719,
      177.19827270507812,
    ],
    "10.1.3": [
      299.3783874511719, 166.24627685546875, 317.7096252441406,
      177.26226806640625,
    ],
    "10.2.1": [
      229.30160522460938, 177.54391479492188, 238.19760131835938,
      188.53591918945312,
    ],
    "10.2.2": [
      290.5016174316406, 177.54391479492188, 299.3976135253906,
      188.53591918945312,
    ],
    "10.2.3": [
      299.3976135253906, 177.58392333984375, 317.7096252441406,
      188.59991455078125,
    ],
    "10.11.1": [
      229.30160522460938, 188.88162231445312, 238.19760131835938,
      199.87362670898438,
    ],
    "10.11.2": [
      252.2480010986328, 188.88162231445312, 299.3760070800781,
      199.87362670898438,
    ],
    "10.11.3": [
      299.3760070800781, 188.921630859375, 317.7096252441406, 199.9376220703125,
    ],
    "10.9.1": [
      231.3040008544922, 201.87515258789062, 240.1999969482422,
      212.86715698242188,
    ],
    "10.9.2": [
      265.81280517578125, 201.87515258789062, 301.38079833984375,
      212.86715698242188,
    ],
    "10.9.4": [
      277.4079895019531, 217.53497314453125, 286.4079895019531,
      226.53497314453125,
    ],
    "25.2.1": [
      526.6375122070312, 97.34407806396484, 569.9725341796875,
      107.67157745361328,
    ],
    25: [
      {
        key: [
          48.340003967285156, 75.45660400390625, 248.20745849609375,
          85.76160430908203,
        ],
        value: [
          528.2949829101562, 75.45660400390625, 569.9798583984375,
          85.76160430908203,
        ],
      },
    ],
    17.1: [
      51.4000244140625, 143.28900146484375, 256.82269287109375,
      152.34368896484375,
    ],
    17.2: [
      51.400001525878906, 213.48101806640625, 168.32662963867188,
      222.53570556640625,
    ],
    17.4: [
      51.400001525878906, 406.2257080078125, 207.0546417236328,
      415.2803955078125,
    ],
    17.5: [
      51.400001525878906, 453.6571044921875, 288.92022705078125,
      462.7117919921875,
    ],
    17.6: [37.0, 93.873291015625, 141.3506317138672, 603.4563598632812],
    18.4: [
      403.0639953613281, 309.00909423828125, 423.0812072753906,
      318.06378173828125,
    ],
    18.5: [
      403.0639953613281, 336.22509765625, 423.0812072753906, 345.27978515625,
    ],
    "18.3.1": [
      427.39837646484375, 261.88800048828125, 516.5311889648438,
      281.7760314941406,
    ],
    "18.1.1": [
      317.79998779296875, 363.05999755859375, 326.79998779296875,
      372.05999755859375,
    ],
    21.1: [
      279.9755859375, 57.43804931640625, 304.9971008300781, 68.75640869140625,
    ],
    21.2: [
      279.9755859375, 110.005126953125, 304.9971008300781, 121.323486328125,
    ],
    21.3: [
      279.9755859375, 142.19927978515625, 304.9971008300781, 153.51763916015625,
    ],
    21.4: [
      290.5469055175781, 174.39337158203125, 304.9985046386719,
      185.71173095703125,
    ],
    21.5: [
      290.5469055175781, 216.77392578125, 304.9985046386719, 228.09228515625,
    ],
    22.1: [
      317.79998779296875, 236.79998779296875, 326.79998779296875,
      245.79998779296875,
    ],
    "23.1.0": [37.0, 433.8880920410156, 58.97600555419922, 444.9040832519531],
    "23.1.1": [109.0, 435.072998046875, 127.23199462890625, 444.127685546875],
    "23.1.5": [421.0, 435.072998046875, 499.156982421875, 444.127685546875],
    "23.2.0": [37.0, 448.6100158691406, 67.46400451660156, 459.6260070800781],
    "23.2.1": [109.0, 449.7950134277344, 169.7772216796875, 467.90570068359375],
    "23.2.5": [421.0, 449.7950134277344, 502.1851806640625, 467.90570068359375],
    "23.3.0": [37.0, 478.0960998535156, 70.29600524902344, 489.1120910644531],
    "23.4.0": [37.0, 492.818115234375, 91.4880142211914, 503.8341064453125],
    "23.5.0": [37.0, 507.54010009765625, 65.67200469970703, 518.5560913085938],
    "23.5.1": [109.0, 508.7249755859375, 138.69720458984375, 517.7796630859375],
    "23.5.5": [421.0, 508.7249755859375, 511.1101989746094, 517.7796630859375],
    "23.6.0": [37.0, 522.2621459960938, 101.08800506591797, 533.2781372070312],
    "23.7.0": [37.0, 536.9840698242188, 76.16800689697266, 557.0560913085938],
    "23.8.0": [37.0, 557.4154663085938, 57.8960075378418, 568.4314575195312],
    "23.8.1": [109.0, 558.5999755859375, 188.73704528808594, 567.6546630859375],
    "23.8.5": [421.0, 558.5999755859375, 497.6870422363281, 567.6546630859375],
    "23.9.0": [37.0, 572.1375122070312, 60.928009033203125, 583.1535034179688],
    "23.9.1": [109.0, 573.322021484375, 163.5972137451172, 582.377197265625],
    "23.9.5": [421.0, 573.322021484375, 475.6002197265625, 582.377197265625],
    "8.11.1.0": [
      48.340003967285156, 333.8106994628906, 205.08998107910156,
      344.1156921386719,
    ],
    "8.11.1.1": [
      48.340003967285156, 333.8106994628906, 205.08998107910156,
      344.1156921386719,
    ],
    "8.12.1.0": [
      48.340003967285156, 344.8987121582031, 193.1499786376953,
      355.2037048339844,
    ],
    "8.12.1.1": [
      48.340003967285156, 344.8987121582031, 193.1499786376953,
      355.2037048339844,
    ],
    "8.13.1.0": [
      48.340003967285156, 355.9866027832031, 185.2449951171875,
      366.2915954589844,
    ],
    "8.13.1.1": [
      48.340003967285156, 355.9866027832031, 185.2449951171875,
      366.2915954589844,
    ],
    PageBox: [0.0, 0.0, 612.0, 792.0],
    PageKeyList: {
      0: [
        "1.7.0",
        "1.1",
        "1.2",
        "1.3",
        "1.4",
        "1.5",
        "1.6",
        "1.7",
        "2.1",
        "2.2",
        "2.3",
        "3.1.0",
        "3.1",
        "3.2",
        "3.3",
        "3.5",
        "3.6",
        "3.4.4",
        "4.3",
        "4.1.1",
        "4.1.2",
        "4.2.2",
        "4.3.2",
        "4.4.2",
        "4.5.2",
        "6.1.1",
        "6.1.2",
        "6.1.3",
        "6.1.4",
        "6.2.1",
        "6.2.2",
        "5.5",
        "5.6.1",
        "5.6.2",
        "5.6.5.0",
      ],
      1: [
        "7.1.1",
        "7.2.1",
        "7.2.0",
        "7.4.1",
        "7.6.1",
        "7.7",
        "7.8.1",
        "7.9.2",
        "7.9.3",
        "8.1.1",
        "8.2.1",
        "8.4.1",
        "8.5",
        "8.6",
        "8.7",
        "8.5.1",
        "8.6.1",
        "8.7.1",
        "8.10.1",
        "8.11",
        "8.11.1",
        "8.12",
        "8.12.1",
        "8.13",
        "8.13.1",
        "8.15",
        "8.16.1",
        "8.18.1",
        "8.19.2",
        "8.19.3",
        "9.1.1",
        "9.2.3",
        "9.2.2",
      ],
      2: [
        "10.10.1",
        "10.10.2",
        "10.10.3",
        "10.1.1",
        "10.1.2",
        "10.1.3",
        "10.2.1",
        "10.2.2",
        "10.2.3",
        "10.11.1",
        "10.11.2",
        "10.11.3",
        "10.9.1",
        "10.9.2",
        "10.9.4",
        "25.2.1",
        "25",
      ],
      3: [
        "17.1",
        "17.2",
        "17.4",
        "17.5",
        "17.6",
        "18.4",
        "18.5",
        "18.3.1",
        "18.1.1",
      ],
      4: [
        "21.1",
        "21.2",
        "21.3",
        "21.4",
        "21.5",
        "22.1",
        "23.1.0",
        "23.1.1",
        "23.1.5",
        "23.2.0",
        "23.2.1",
        "23.2.5",
        "23.3.0",
        "23.4.0",
        "23.5.0",
        "23.5.1",
        "23.5.5",
        "23.6.0",
        "23.7.0",
        "23.8.0",
        "23.8.1",
        "23.8.5",
        "23.9.0",
        "23.9.1",
        "23.9.5",
      ],
    },
  },
};

// GET ALL DATA
mock.onGet("/api/pdf/escrow/coordinate").reply(200, data.contents);
