import mock from "../../mock";

const data = {
  contents: {
    "1.7.0": [
      36.954010009765625, 178.54608154296875, 116.49000549316406,
      189.56207275390625,
    ],
    1.1: [
      122.010009765625, 96.97502136230469, 160.50303649902344,
      109.34102630615234,
    ],
    1.2: [
      121.90400695800781, 107.77201843261719, 160.39703369140625,
      120.13802337646484,
    ],
    1.3: [
      121.89000701904297, 119.01103210449219, 160.38304138183594,
      131.3770294189453,
    ],
    1.4: [
      118.86499786376953, 131.166015625, 210.53900146484375, 141.9840087890625,
    ],
    1.5: [
      118.86499786376953, 141.36399841308594, 183.24203491210938,
      153.72999572753906,
    ],
    1.6: [
      118.86499786376953, 154.15399169921875, 191.55502319335938,
      175.89898681640625,
    ],
    1.7: [36.0, 174.33799743652344, 155.9130401611328, 190.9070587158203],
    2.1: [
      264.4429931640625, 96.84502410888672, 335.2729797363281,
      129.00897216796875,
    ],
    2.2: [],
    2.3: [264.4429931640625, 127.37895202636719, 282.875, 139.7449493408203],
    "3.1.0": [
      474.09698486328125, 96.83299255371094, 504.8320007324219,
      109.1989974975586,
    ],
    3.1: [
      474.09698486328125, 96.83299255371094, 504.8320007324219,
      109.1989974975586,
    ],
    3.2: [
      474.1399841308594, 108.44499206542969, 511.093994140625,
      120.81099700927734,
    ],
    3.3: [
      474.0669860839844, 118.68601989746094, 512.8839721679688,
      131.05201721191406,
    ],
    3.5: [
      474.25799560546875, 162.4530487060547, 520.4280395507812,
      174.8190460205078,
    ],
    3.6: [],
    "3.4.1": [480.375, 143.19097900390625, 486.625, 149.44097900390625],
    4.3: [
      40.94500732421875, 289.3220520019531, 165.60501098632812,
      303.0920715332031,
    ],
    "4.1.1": [
      187.8759765625, 219.1200408935547, 241.0480194091797, 238.35604858398438,
    ],
    "4.1.2": [
      299.8119812011719, 219.73007202148438, 316.69598388671875,
      236.2540740966797,
    ],
    "4.2.1": [
      187.8759765625, 246.4070281982422, 206.14598083496094, 265.6430358886719,
    ],
    "4.2.2": [
      299.8139953613281, 247.23410034179688, 316.697998046875,
      263.7580871582031,
    ],
    "4.3.1": [
      187.63397216796875, 287.74505615234375, 243.70401000976562,
      306.9810485839844,
    ],
    "4.3.2": [
      299.82000732421875, 287.9480895996094, 316.7040100097656,
      304.4720764160156,
    ],
    "4.4.2": [
      299.7740173339844, 357.1360778808594, 316.65802001953125,
      373.6600646972656,
    ],
    "4.5.2": [
      299.8970031738281, 380.694091796875, 316.781005859375, 397.21807861328125,
    ],
    "6.1.1": [
      182.75900268554688, 688.7731323242188, 238.82904052734375,
      708.0090942382812,
    ],
    "6.1.2": [343.0, 690.97998046875, 539.7890014648438, 704.8630981445312],
    "6.1.3": [343.0, 690.97998046875, 539.7890014648438, 704.8630981445312],
    "6.1.4": [343.0, 690.97998046875, 539.7890014648438, 704.8630981445312],
    "6.2.1": [
      182.82400512695312, 722.7850952148438, 238.89404296875, 742.0210571289062,
    ],
    "6.2.2": [
      325.0840148925781, 743.2150268554688, 332.5840148925781,
      750.7150268554688,
    ],
    5.5: [
      49.37699890136719, 528.9259643554688, 128.72698974609375,
      553.6959838867188,
    ],
    "5.1.1": [
      350.448974609375, 439.4259948730469, 397.06890869140625,
      453.1960144042969,
    ],
    "5.2.1": [
      354.47998046875, 459.8760070800781, 394.5290222167969, 473.6159973144531,
    ],
    "5.2.2": [
      354.47998046875, 459.8760070800781, 394.5290222167969, 473.6159973144531,
    ],
    "5.3.1": [
      377.0690002441406, 479.8760070800781, 382.1990051269531,
      493.6159973144531,
    ],
    "5.4.1": [
      366.8089904785156, 499.8760070800781, 394.5290222167969,
      513.6160278320312,
    ],
    "5.5.1": [
      345.7239990234375, 531.2760009765625, 401.7940673828125, 550.511962890625,
    ],
    "5.6.2": [
      193.55599975585938, 601.3759765625, 228.56600952148438, 615.115966796875,
    ],
    "5.6.3.1": [
      473.85601806640625, 572.4259643554688, 490.6260070800781,
      586.1959838867188,
    ],
    "5.6.1": [
      193.55599975585938, 582.2760009765625, 239.54603576660156,
      601.511962890625,
    ],
    "5.6.4.1": [
      473.85601806640625, 586.4259643554688, 490.6260070800781,
      600.1959838867188,
    ],
    "5.6.5.0": [291.875, 601.3759765625, 366.69500732421875, 615.115966796875],
    "5.6.5.1": [
      473.85601806640625, 601.4259643554688, 487.926025390625,
      615.1959838867188,
    ],
    "5.6.3": [
      285.43499755859375, 576.3759765625, 292.93499755859375, 583.8759765625,
    ],
    "5.6.4": [
      285.43499755859375, 590.3759765625, 292.93499755859375, 597.8759765625,
    ],
    "5.6.5": [
      285.43499755859375, 605.3759765625, 292.93499755859375, 612.8759765625,
    ],
    "5.1.1.1": [
      350.448974609375, 439.4259948730469, 397.06890869140625,
      453.1960144042969,
    ],
    "5.1.1.2": [
      350.448974609375, 439.4259948730469, 397.06890869140625,
      453.1960144042969,
    ],
    "7.1.1": [
      424.04498291015625, 67.30999755859375, 452.7649841308594,
      78.32599639892578,
    ],
    "7.2.1": [
      47.90700149536133, 78.2699966430664, 157.83798217773438,
      89.26199340820312,
    ],
    "7.2.0": [
      47.90700149536133, 78.2699966430664, 157.83798217773438,
      89.26199340820312,
    ],
    7.2: {
      "7.2.2": [
        410.1260070800781, 78.2699966430664, 436.4060363769531,
        89.26199340820312,
      ],
    },
    7.3: [
      {
        "7.3.2": [
          410.1260070800781, 88.2699966430664, 436.4060363769531,
          99.26199340820312,
        ],
        7.3: [
          47.90700149536133, 88.2699966430664, 114.42201232910156,
          99.26199340820312,
        ],
      },
    ],
    "7.4.1": [
      420.78497314453125, 159.30999755859375, 456.02496337890625,
      170.32598876953125,
    ],
    7.5: [
      {
        "7.5.2": [
          410.1260070800781, 170.26998901367188, 436.4060363769531,
          181.26199340820312,
        ],
        7.5: [
          47.90700149536133, 170.26998901367188, 97.95001220703125,
          181.26199340820312,
        ],
        "7.5.1": [
          196.75, 170.26998901367188, 297.2059631347656, 181.26199340820312,
        ],
      },
      {
        "7.5.2": [
          414.2300109863281, 180.26998901367188, 436.4060363769531,
          191.26199340820312,
        ],
        7.5: [
          47.90700149536133, 180.26998901367188, 97.59800720214844,
          191.26199340820312,
        ],
        "7.5.1": [
          196.75, 180.26998901367188, 247.00599670410156, 191.26199340820312,
        ],
      },
      {
        "7.5.2": [
          414.2300109863281, 190.26998901367188, 436.4060363769531,
          201.26199340820312,
        ],
        7.5: [
          47.90700149536133, 190.26998901367188, 114.64601135253906,
          201.26199340820312,
        ],
        "7.5.1": [
          196.75, 190.26998901367188, 288.75799560546875, 201.26199340820312,
        ],
      },
      {
        "7.5.2": [
          410.1260070800781, 200.26998901367188, 436.4060363769531,
          211.26199340820312,
        ],
        7.5: [
          47.90700149536133, 200.26998901367188, 248.25399780273438,
          211.26199340820312,
        ],
      },
      {
        "7.5.2": [
          410.1260070800781, 210.26998901367188, 436.4060363769531,
          221.26199340820312,
        ],
        7.5: [
          47.90700149536133, 210.26998901367188, 248.25399780273438,
          221.26199340820312,
        ],
      },
      {
        "7.5.2": [
          414.2300109863281, 220.26998901367188, 436.4060363769531,
          231.26199340820312,
        ],
        7.5: [
          53.086002349853516, 220.26998901367188, 248.25099182128906,
          231.26199340820312,
        ],
      },
    ],
    "7.8.1": [
      420.7850036621094, 363.30999755859375, 456.0249938964844,
      374.32598876953125,
    ],
    "7.9.2": [
      404.3659973144531, 374.2699890136719, 436.4060363769531,
      385.2619934082031,
    ],
    "8.1.1": [
      424.04498291015625, 413.30999755859375, 452.7649841308594,
      424.32598876953125,
    ],
    "8.2.1": [196.75, 424.2699890136719, 294.0540466308594, 435.2619934082031],
    "8.2.2": [
      410.1260070800781, 424.2699890136719, 436.4060363769531,
      435.2619934082031,
    ],
    8.2: [
      47.90700149536133, 424.2699890136719, 104.06201171875, 435.2619934082031,
    ],
    "8.4.1": [
      424.0450134277344, 445.30999755859375, 452.7650146484375,
      456.32598876953125,
    ],
    8.5: [
      47.90700149536133, 456.2699890136719, 189.7659912109375,
      467.2619934082031,
    ],
    8.6: [
      47.90700149536133, 466.2699890136719, 176.35800170898438,
      477.2619934082031,
    ],
    "8.7.2": [
      410.1260070800781, 476.2699890136719, 436.4060363769531,
      487.2619934082031,
    ],
    8.7: [
      47.90700149536133, 476.2699890136719, 242.16604614257812,
      487.2619934082031,
    ],
    8.8: [
      47.90700149536133, 486.2699890136719, 126.39800262451172,
      497.2619934082031,
    ],
    "8.5.1": [
      47.90700149536133, 456.2699890136719, 189.7659912109375,
      467.2619934082031,
    ],
    "8.6.1": [
      47.90700149536133, 466.2699890136719, 176.35800170898438,
      477.2619934082031,
    ],
    "8.8.1": [
      47.90700149536133, 486.2699890136719, 126.39800262451172,
      497.2619934082031,
    ],
    "8.7.1": [
      47.90700149536133, 476.2699890136719, 242.16604614257812,
      487.2619934082031,
    ],
    "8.10.1": [
      420.7850036621094, 507.30999755859375, 456.0249938964844,
      518.3259887695312,
    ],
    "8.11.2": [
      410.1260070800781, 518.27001953125, 436.4060363769531, 529.2620239257812,
    ],
    8.11: [
      47.90700149536133, 518.27001953125, 133.51800537109375, 529.2620239257812,
    ],
    "8.11.1": [
      172.57400512695312, 518.27001953125, 263.4700012207031, 529.2620239257812,
    ],
    8.12: [
      47.90700149536133, 528.27001953125, 120.1100082397461, 539.2620239257812,
    ],
    "8.12.1": [196.75, 528.27001953125, 262.75799560546875, 539.2620239257812],
    "8.13.2": [
      404.3659973144531, 538.27001953125, 436.4060363769531, 549.2620239257812,
    ],
    8.13: [
      47.90700149536133, 538.27001953125, 102.35000610351562, 549.2620239257812,
    ],
    "8.13.1": [
      168.47000122070312, 538.27001953125, 263.4700012207031, 549.2620239257812,
    ],
    "8.15.2": [
      418.3340148925781, 588.27001953125, 436.4060363769531, 599.2620239257812,
    ],
    8.15: [
      47.90700149536133, 588.27001953125, 129.57400512695312, 599.2620239257812,
    ],
    "8.18.1": [
      420.78497314453125, 691.3099975585938, 456.02496337890625,
      702.3259887695312,
    ],
    "8.19.2": [
      404.3659973144531, 702.27001953125, 436.4060363769531, 713.2620239257812,
    ],
    "9.1.1": [
      420.78497314453125, 725.3099975585938, 456.02496337890625,
      736.3259887695312,
    ],
    "9.2.2": [
      404.3659973144531, 736.27001953125, 436.4060363769531, 747.2620239257812,
    ],
    10.3: [
      489.4779968261719, 707.2330322265625, 521.5180053710938,
      718.2250366210938,
    ],
    "10.10.1": [
      196.79595947265625, 606.89599609375, 237.04400634765625,
      617.8880004882812,
    ],
    "10.1.1": [
      202.7569580078125, 622.9750366210938, 237.25299072265625,
      633.967041015625,
    ],
    "10.2.1": [
      228.74896240234375, 656.718994140625, 236.95697021484375,
      667.7109985351562,
    ],
    "10.11.1": [
      194.2599639892578, 672.1520385742188, 236.96400451660156, 683.14404296875,
    ],
    "10.9.1": [
      212.8369598388672, 685.7340087890625, 237.11697387695312, 696.75,
    ],
    "10.10.2": [
      256.7789611816406, 607.06005859375, 297.0270080566406, 618.0520629882812,
    ],
    "10.1.2": [
      262.6519470214844, 622.9750366210938, 297.1479797363281, 633.967041015625,
    ],
    "10.2.2": [
      289.04193115234375, 656.7740478515625, 297.24993896484375,
      667.7660522460938,
    ],
    "10.11.2": [
      254.44393920898438, 672.2059936523438, 297.1479797363281,
      683.197998046875,
    ],
    "10.9.2": [
      261.9559326171875, 685.7509765625, 297.1959228515625, 696.7669677734375,
    ],
    "10.10.3": [
      305.96893310546875, 606.6630249023438, 317.22491455078125,
      617.6790161132812,
    ],
    "10.1.3": [
      305.9259338378906, 618.9010009765625, 319.3419494628906, 629.9169921875,
    ],
    "10.2.3": [
      305.9649353027344, 654.5430297851562, 317.2209167480469,
      665.5590209960938,
    ],
    "10.11.3": [
      306.0809326171875, 670.9569702148438, 319.4969482421875,
      681.9729614257812,
    ],
    "10.1.4": [
      330.95391845703125, 618.85595703125, 476.73699951171875,
      628.4739990234375,
    ],
    "10.11.4": [
      330.95098876953125, 670.633056640625, 433.3190002441406,
      681.6250610351562,
    ],
    "10.3.2": [
      489.4779968261719, 707.2330322265625, 521.5180053710938,
      718.2250366210938,
    ],
    "10.9.3": [183.25, 699.364990234375, 192.0, 708.114990234375],
    "10.9.4": [183.25, 699.364990234375, 192.0, 708.114990234375],
    "25.2.1": [
      531.8849487304688, 521.6890258789062, 572.1329956054688,
      532.6810302734375,
    ],
    25: [
      {
        key: [
          54.8129997253418, 79.3539810180664, 136.72500610351562,
          90.34597778320312,
        ],
        value: [
          531.8159790039062, 79.49600982666016, 572.0640258789062,
          90.48800659179688,
        ],
      },
    ],
    17.1: [
      44.04499816894531, 135.38201904296875, 270.6740417480469,
      146.20001220703125,
    ],
    17.2: [
      44.04499816894531, 204.38201904296875, 171.11605834960938,
      215.20001220703125,
    ],
    17.4: [
      44.04499816894531, 410.38201904296875, 214.91004943847656,
      421.20001220703125,
    ],
    17.5: [
      42.1150016784668, 502.3815002441406, 190.59071350097656, 513.199951171875,
    ],
    "17.3.1": [
      35.999000549316406, 235.88201904296875, 230.56224060058594,
      257.8320007324219,
    ],
    "17.3.3": [
      35.999000549316406, 235.88201904296875, 230.56224060058594,
      257.8320007324219,
    ],
    17.6: [36.0, 89.04901123046875, 175.5518035888672, 612.7034912109375],
    18.1: [315.25, 113.280029296875, 322.75, 120.780029296875],
    18.2: [401.0, 197.97702026367188, 433.0400390625, 208.96902465820312],
    18.3: [
      406.7760009765625, 237.40603637695312, 433.0560302734375,
      248.39804077148438,
    ],
    18.5: [
      406.8280029296875, 324.7780456542969, 433.1080322265625,
      335.7700500488281,
    ],
    18.4: [
      401.135009765625, 290.2320556640625, 433.175048828125, 301.22406005859375,
    ],
    "18.2.1": [
      438.84002685546875, 210.79205322265625, 565.3680419921875,
      221.62405395507812,
    ],
    "18.3.1": [
      438.93701171875, 252.87408447265625, 471.28900146484375,
      263.7060852050781,
    ],
    21.1: [
      252.94898986816406, 68.91203308105469, 298.22802734375, 81.27803802490234,
    ],
    21.2: [
      252.7889862060547, 105.78501892089844, 298.0680236816406,
      118.1510238647461,
    ],
    21.3: [
      253.62498474121094, 141.0940399169922, 298.9040222167969,
      153.4600372314453,
    ],
    21.4: [
      273.97198486328125, 183.8950653076172, 298.72198486328125,
      196.2610626220703,
    ],
    21.5: [
      264.91998291015625, 227.9310760498047, 298.90399169921875,
      240.2970733642578,
    ],
    22.1: [
      311.6409912109375, 231.469970703125, 319.1409912109375, 238.969970703125,
    ],
    "23.1.0": [
      39.8909912109375, 433.5249938964844, 63.34498977661133, 445.9179992675781,
    ],
    "23.1.1": [
      140.9290008544922, 433.7550048828125, 157.31300354003906,
      444.74700927734375,
    ],
    "23.1.5": [
      457.2860107421875, 433.7550048828125, 457.2860107421875,
      444.3550109863281,
    ],
    "23.2.0": [
      39.891998291015625, 449.5249938964844, 72.50799560546875,
      461.9179992675781,
    ],
    "23.2.1": [
      140.9290008544922, 449.7550048828125, 196.05702209472656,
      469.74700927734375,
    ],
    "23.2.5": [
      511.2220764160156, 449.7550048828125, 488.9180603027344,
      469.3550109863281,
    ],
    "23.3.0": [
      39.891998291015625, 474.5249938964844, 73.8219985961914,
      486.9179992675781,
    ],
    "23.3.1": [
      140.9290008544922, 475.739013671875, 165.5530242919922, 485.3550109863281,
    ],
    "23.4.0": [
      39.891998291015625, 490.5249938964844, 96.06099700927734,
      502.9179992675781,
    ],
    "23.4.5": [
      433.7900085449219, 491.739013671875, 450.2060241699219, 501.3550109863281,
    ],
    "23.5.0": [
      39.891998291015625, 506.5250244140625, 71.13099670410156,
      518.9180297851562,
    ],
    "23.5.1": [
      140.9290008544922, 507.7389831542969, 168.14500427246094, 517.35498046875,
    ],
    "23.5.5": [
      433.7900085449219, 506.7549743652344, 483.19805908203125,
      517.7470092773438,
    ],
    "23.6.0": [
      39.891998291015625, 522.5250244140625, 106.87899780273438,
      534.9180297851562,
    ],
    "23.6.1": [
      140.9290008544922, 523.7389526367188, 165.5530242919922, 533.35498046875,
    ],
    "23.7.0": [
      39.891998291015625, 538.5250244140625, 129.1179962158203,
      550.9180297851562,
    ],
    "23.8.0": [
      39.891998291015625, 554.5250244140625, 62.13099670410156,
      566.9180297851562,
    ],
    "23.8.1": [
      155.78500366210938, 554.7550048828125, 198.88101196289062,
      565.35498046875,
    ],
    "23.8.5": [
      433.7900085449219, 554.7550048828125, 493.39801025390625, 565.35498046875,
    ],
    "23.9.0": [
      39.891998291015625, 579.5250244140625, 65.61399841308594,
      591.9180297851562,
    ],
    "23.9.1": [
      153.24099731445312, 579.7550048828125, 186.88101196289062,
      590.35498046875,
    ],
    "23.9.5": [
      433.7900085449219, 579.7550048828125, 479.7420349121094, 590.35498046875,
    ],
    "8.11.1.0": [
      172.57400512695312, 518.27001953125, 263.4700012207031, 529.2620239257812,
    ],
    "8.11.1.1": [
      172.57400512695312, 518.27001953125, 263.4700012207031, 529.2620239257812,
    ],
    "8.12.1.0": [
      196.75, 528.27001953125, 262.75799560546875, 539.2620239257812,
    ],
    "8.12.1.1": [
      196.75, 528.27001953125, 262.75799560546875, 539.2620239257812,
    ],
    "8.13.1.0": [
      168.47000122070312, 538.27001953125, 263.4700012207031, 549.2620239257812,
    ],
    "8.13.1.1": [
      168.47000122070312, 538.27001953125, 263.4700012207031, 549.2620239257812,
    ],
    PageBox: [0.0, 0.0, 612.0, 792.0],
    PageKeyList: {
      0: [
        "1.7.0",
        "1.1",
        "1.2",
        "1.3",
        "1.4",
        "1.5",
        "1.6",
        "1.7",
        "2.1",
        "2.2",
        "2.3",
        "3.1.0",
        "3.1",
        "3.2",
        "3.3",
        "3.5",
        "3.6",
        "3.4.1",
        "4.3",
        "4.1.1",
        "4.1.2",
        "4.2.1",
        "4.2.2",
        "4.3.1",
        "4.3.2",
        "4.4.2",
        "4.5.2",
        "6.1.1",
        "6.1.2",
        "6.1.3",
        "6.1.4",
        "6.2.1",
        "6.2.2",
        "5.5",
        "5.1.1",
        "5.2.1",
        "5.2.2",
        "5.3.1",
        "5.4.1",
        "5.5.1",
        "5.6.2",
        "5.6.3.1",
        "5.6.1",
        "5.6.4.1",
        "5.6.5.0",
        "5.6.5.1",
        "5.6.3",
        "5.6.4",
        "5.6.5",
        "5.1.1.1",
        "5.1.1.2",
      ],
      1: [
        "7.1.1",
        "7.2.1",
        "7.2.0",
        "7.2",
        "7.3",
        "7.4.1",
        "7.5",
        "7.8.1",
        "7.9.2",
        "8.1.1",
        "8.2.1",
        "8.2.2",
        "8.2",
        "8.4.1",
        "8.5",
        "8.6",
        "8.7.2",
        "8.7",
        "8.8",
        "8.5.1",
        "8.6.1",
        "8.8.1",
        "8.7.1",
        "8.10.1",
        "8.11.2",
        "8.11",
        "8.11.1",
        "8.12",
        "8.12.1",
        "8.13.2",
        "8.13",
        "8.13.1",
        "8.15.2",
        "8.15",
        "8.18.1",
        "8.19.2",
        "9.1.1",
        "9.2.2",
      ],
      2: [
        "10.3",
        "10.10.1",
        "10.1.1",
        "10.2.1",
        "10.11.1",
        "10.9.1",
        "10.10.2",
        "10.1.2",
        "10.2.2",
        "10.11.2",
        "10.9.2",
        "10.10.3",
        "10.1.3",
        "10.2.3",
        "10.11.3",
        "10.1.4",
        "10.11.4",
        "10.3.2",
        "10.9.3",
        "10.9.4",
        "25.2.1",
        "25",
      ],
      3: [
        "17.1",
        "17.2",
        "17.4",
        "17.5",
        "17.3.1",
        "17.3.3",
        "17.6",
        "18.1",
        "18.2",
        "18.3",
        "18.5",
        "18.4",
        "18.2.1",
        "18.3.1",
      ],
      4: [
        "21.1",
        "21.2",
        "21.3",
        "21.4",
        "21.5",
        "22.1",
        "23.1.0",
        "23.1.1",
        "23.1.5",
        "23.2.0",
        "23.2.1",
        "23.2.5",
        "23.3.0",
        "23.3.1",
        "23.4.0",
        "23.4.5",
        "23.5.0",
        "23.5.1",
        "23.5.5",
        "23.6.0",
        "23.6.1",
        "23.7.0",
        "23.8.0",
        "23.8.1",
        "23.8.5",
        "23.9.0",
        "23.9.1",
        "23.9.5",
      ],
    },
  },
};

// GET ALL DATA
mock.onGet("/api/pdf/lender/coordinate").reply(200, data.contents);
